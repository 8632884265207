
/* --------------------------------------------------------------------------
   Alert
   -------------------------------------------------------------------------- */


@mixin ui-alert-variant($name, $background, $color: "") {
  @if $color == "" {
    $color: color($background, dark);
  } @else {
    $color: color($color);
  }

  .ui-alert--#{$name} {
    background-color: color($background, light);
    color: $color;

    .alert-dismiss {
      &:hover {
        color: shade(color($background), 90%);
      }
    }
    a:not(.ui-button) {
      color: $color;

      &:hover {
        color: shade($color, 90%);
      }
    }
    .ui-ico--ball {
      background-color: $color;
    }
  }
}

.ui-alert {
  display: block;
  padding: $alert-padding-md-y $alert-padding-md-x;
  border-radius: $alert-border-radius;
  line-height: 1.25;

  p {
    margin-bottom: $spacer-2;

    &:last-child {
      margin-bottom: 0;
    }
  }
  a:not(.ui-button) {
    text-decoration: underline;
  }
}

.ui-alert-holder,
.ui-alert {
  display: block;
  margin-bottom: $spacer-md;

  &:last-child,
  > *:last-child {
    &:not(:only-child) {
      margin-bottom: 0;
    }
  }

  .ui-alert-holder & {
    margin-bottom: 0;
  }
}

.ui-alert--xs {
  margin-bottom: $spacer-sm;
  padding: $alert-padding-md-xs-y $alert-padding-md-xs-x;
  border-radius: $alert-border-radius-sm;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}
.ui-alert--sm {
  margin-bottom: $spacer-sm;
  padding: $alert-padding-md-xs-y $alert-padding-md-xs-x;
  border-radius: $alert-border-radius-sm;
}
.ui-alert--lg {
  margin-bottom: $spacer-lg;
  padding: $alert-padding-lg-y $alert-padding-lg-x;
}

.alert-dismiss {
  float: right;
  min-width: 0 !important;
  margin-left: .5rem;
  margin-bottom: $spacer-2;
}


@include ui-alert-variant('default', color("gray", lightest), black);
@include ui-alert-variant('success', success);
@include ui-alert-variant('fullypaid', fullypaid);
@include ui-alert-variant('attention', attention);
@include ui-alert-variant('danger', danger);
@include ui-alert-variant('info', info);

@include ui-alert-variant('blue', "blue");
@include ui-alert-variant('petrol', "petrol");
@include ui-alert-variant('purple', "purple");
@include ui-alert-variant('red', "red");
