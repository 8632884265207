

/* Paragraphs
   -------------------------------------------------------------------------- */

p {
  &.text-xs,
  &.text-sm {
    margin-bottom: $spacer-2;
  }
}


/* Headings
   -------------------------------------------------------------------------- */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $heading-margin-bottom;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  color: $heading-color;

  strong {
    font-weight: 700;
  }
}

h1, .h1 {
  font-size: $font-size-h1;
  margin-bottom: 1rem;
}
h2, .h2 {
  font-size: $font-size-h2;
  margin-bottom: 1rem;
}
h3, .h3 {
  font-size: $font-size-h3;
  margin-bottom: 1rem;
}
h4, .h4 {
  font-size: $font-size-h4;
  margin-bottom: $spacer-2;
}
h5, .h5,
h6, .h6 {
  font-size: $font-size-h5;
  margin-bottom: .25rem;
}

.display-1 {
  font-size: $font-size-h1;
  margin-bottom: 1rem;

  @include media-breakpoint-up('sm') {
    font-size: 3.5rem;
  }
}


/* Horizontal rulers
   -------------------------------------------------------------------------- */

hr {
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  border: 0;
  border-top: $border-width solid $border-color;
}


/* Emphasis
   -------------------------------------------------------------------------- */


b, strong {
  font-weight: 500;
}
small,
.small {
  //font-size: $small-font-size;
  font-weight: normal;
}
.lead {
  margin-bottom: $spacer-2;
  font-size: 1.125rem;
  font-weight: 500;
}
.text-underline {
  text-decoration: underline !important;
}
.text-eyebrow {
  margin-bottom: .25em;
  font-size: 1em;
  color: color("gray");
}



/* Positioning
   -------------------------------------------------------------------------- */

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}


/* Variants
   -------------------------------------------------------------------------- */

.text-italic {
  font-style: italic;
}
.text-normal {
  font-weight: 400 !important;
}
.text-medium {
  font-weight: 500 !important;
}
.text-bold {
  font-weight: 700 !important;
}


/* Sizes
   -------------------------------------------------------------------------- */

.text-xs,
.text-xs p {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
}
.text-sm,
.text-sm p {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}
.text-md,
.text-md p {
  font-size: $font-size-base;
  line-height: $line-height-base;
}
.text-lg,
.text-lg p {
  font-size: $font-size-lg;
  line-height: $line-height-lg;
}
.text-xl,
.text-xl p {
  font-size: $font-size-lg;
  line-height: $line-height-lg;

  @include media-breakpoint-up('md') {
    font-size: $font-size-xl;
    line-height: $line-height-lg;
  }
}


/* Colors
   -------------------------------------------------------------------------- */

.text-muted {
  color: $text-muted;
}
.text-inverse {
  color: color("white", dark);
}

.text-black {
  color: color("black") !important;
}

.text-success {
  color: color(success) !important;
}
.text-attention {
  color: color(attention) !important;
}
.text-danger {
  color: color(danger) !important;
}
.text-info {
  color: color(info) !important;
}

.text-primary {
  color: color(primary);
}

@include color-component("text", "color");


/* Spacing
   -------------------------------------------------------------------------- */

.text-block {
  margin-bottom: $spacer-6;

  &-lg {
    margin-bottom: $spacer-6;

    @include media-breakpoint-up('lg') {
      margin-bottom: $spacer-8;
    }
  }
}
.text-nowrap {
  white-space: nowrap;
}
.text-no-white-space {
  letter-spacing: -0.31em;

  > * {
    letter-spacing: normal;
  }
}
