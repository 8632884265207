
#page-onboarding {
  .page-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .page-content {
    padding-top: $spacer-xl;
    padding-bottom: $spacer-xl;

    @include media-breakpoint-up('md') {
      padding-top: 60px;
      padding-bottom: 120px;
    }
  }


  h1 {
    margin-bottom: $spacer-md;

    @include media-breakpoint-up('md') {
      margin-bottom: $spacer-xxl;
    }
  }

  .company-search-selection {
    margin-top: 2rem;
  }

  .onboarding-step-card {
    > .ui-card__block {
      max-width: 550px;
      margin: auto;
      padding-top: $spacer-6;
      padding-bottom: $spacer-6;

      @include media-breakpoint-up('md') {
        padding-top: $spacer-8;
        padding-bottom: $spacer-8;
      }

      @include media-breakpoint-up('lg') {
        padding-top: $spacer-9;
        padding-bottom: $spacer-9;
      }
    }
  }

}

.onboarding-voucher {
  margin-bottom: $spacer-6;
}

.onboarding-logout {
  text-align: right;

  @include media-breakpoint-down('sm') {
    float: right;
    margin: -1rem -1rem $spacer-sm .5rem;
  }
  @include media-breakpoint-up('sm') {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
  }
}

.onboarding-questions {
  position: relative;
  z-index: 1;

  header {
    padding-bottom: $spacer-lg;
    margin-bottom: $spacer-xl;
    border-bottom: $border-width solid rgba(black, .12);
  }

  .payment-details {
    margin-bottom: 60px;
  }

  .questions-expand-button {
    text-align: right;
    margin-bottom: $spacer-sm;
  }

  .info-box {
    padding-bottom: $spacer-md;

    .ui-alert {
      margin-bottom: .25rem;
    }
    .ui-action-button {
      position: absolute;
      right: 0;
    }
  }

  .other-sales-channel-checkbox {
    display: flex;
    width: 100%;
    align-items: center;

    .ui-checkbox__indicator {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: $spacer-2;
    }
    .ui-input {
      flex-grow: 1;
    }
  }
}


.onboarding-success {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: $spacer-xxl;
  }
  .h1 {
    margin-bottom: $spacer-xl;
  }
  .lead {
    margin-bottom: $spacer-xxl;
  }
}
