
/* --------------------------------------------------------------------------
   Fonts
   -------------------------------------------------------------------------- */


@font-face {
  font-family: 'Intrum Sans';
  font-weight: 400;
  font-style: normal;
  src:  url('#{$font-path}/IntrumSans-Regular.woff2') format('woff2'),
        url('#{$font-path}/IntrumSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Intrum Sans';
  font-weight: 400;
  font-style: italic;
  src:  url('#{$font-path}/IntrumSans-RegularItalic.woff2') format('woff2'),
        url('#{$font-path}/IntrumSans-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Intrum Sans';
  font-weight: 500;
  font-style: normal;
  src:  url('#{$font-path}/IntrumSans-Medium.woff2') format('woff2'),
        url('#{$font-path}/IntrumSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Intrum Sans';
  font-weight: 500;
  font-style: italic;
  src:  url('#{$font-path}/IntrumSans-MediumItalic.woff2') format('woff2'),
        url('#{$font-path}/IntrumSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Intrum Sans';
  font-weight: 700;
  font-style: normal;
  src:  url('#{$font-path}/IntrumSans-Bold.woff2') format('woff2'),
        url('#{$font-path}/IntrumSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Intrum Sans';
  font-weight: 700;
  font-style: italic;
  src:  url('#{$font-path}/IntrumSans-BoldItalic.woff2') format('woff2'),
        url('#{$font-path}/IntrumSans-BoldItalic.woff') format('woff');
}

