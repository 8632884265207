.page-backdrop {
  position: absolute;
  z-index: $z-backdrop;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(color("gray", light), .5);
}

.page-backdrop--cover-header {
  z-index: $z-backdrop-top;
}
