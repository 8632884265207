
/* --------------------------------------------------------------------------
   Color
   -------------------------------------------------------------------------- */


$variations: (
  fade: (
    function: rgba,
    parameters: .5
  ),

  light: (
    function: tint,
    parameters: 25%
  ),
  lighter: (
    function: tint,
    parameters: 50%
  ),
  dark: (
    function: shade,
    parameters: 60%
  ),
);

@function get-color($color-name, $variant: regular, $variation: false, $contrast: false) {
  // @if not is-color($color-name) {
  //   $color-name: quote($color-name);
  // }
  //$color-name: unquote($color-name);
  //$color-name: quote($color-name);
  $color: null;

  // if $color exists:
  @if map-has-key($colors, $color-name) {
    $color: map-get($colors, $color-name);

    @if is-map($color) {
      // if $color $variant exists, select it
      @if map-has-key($color, $variant) {
        $color: map-get($color, $variant);

        @if is-map($color) {
          @if $contrast {

            @if map-has-key($color, contrast) {
              $color: map-get($color, contrast);
            } @else {
              @error "⚠️  No contrasting color set for `#{$color-name}` `#{$variant}`.";
            }

          } @else {

            @if map-has-key($color, color) {
              $color: map-get($color, color);
            } @else {
              @error "⚠️  No color value set for `#{$color-name}`.";
            }

          }
        }
      } @else if map-has-key($color, regular) {
        $color: map-get($color, regular);
      } @else {
        @error "⚠️  Invalid color variant `#{$variant}` of `#{$color-name}`.";
      }
    }

  } @else if map-has-key($contextual-colors, $color-name) {
    $color: map-get($contextual-colors, $color-name);

    @if not is-color($color) {
      @if $color-name != unquote($color) {
        $color: get-color($color, $variant, $variation, $contrast);
      } @else {
        @error "⚠️  You're trying to head into a hell of an inception ride...";
      }
    } @else if $variant {
      @if $variant == "light" {
        $variation: "lighter";
      } @else if map-get($variations, $variant) {
        $variation: $variant;
      }
    }
  } @else if is-color($color-name) {
    $color: $color-name;
  } @else {
    // $color is not in $color and $color is not a color
    @error "⚠️  Invalid color name `#{$color-name}`.";
  }

  @if $variation and $color {
    @if not map-has-key($variations, $variation) {
      // variation is not in $variations
      @error "⚠️  Invalid $variation `#{$variation}`.";
    } @else {
      // make it easier to deal with nested map
      $this-variation: map-get($variations, $variation);
      // $args = $function, $color
      $function: get-function(map-get($this-variation, function));
      $args: join($function, $color);
      @if map-get($this-variation, parameters) {
        // $args = $function, $colors, $parameters
        $args: join($args, map-get($this-variation, parameters));
      }
      //@return $args;
      @return call($args...);
    }
  }

  @return $color;

}

@function color($color, $variant: regular, $variation: false) {
  @return get-color($color, $variant, $variation);
}
@function color-contrast($color, $variant: regular) {
  @return get-color($color, $variant, false, true);
}


@mixin color-component($prefix, $attr, $faded: false) {
  @each $color, $color-value in $colors {
    @each $color-variant, $value in $color-value {
      $selector: null;

      @if is-map($value) {
        @if map-has-key($value, "color") {
          $value: map-get($value, "color");
        }
      }

      @if $color-variant == "regular" {
        $selector: "#{$prefix}-#{$color}";
      } @else {
        $selector: "#{$prefix}-#{$color}-#{$color-variant}";
      }

      .#{$selector} {
        #{$attr}: $value;

        @content;

        @if $faded {
          &.#{$prefix}--faded {
            #{$attr}: rgba($value, .5);
          }
        }
      }

    }
  }
}
@mixin color-comp {
  @each $color, $color-value in $colors {
    @each $color-variant, $value in $color-value {
      $selector: null;

      @if is-map($value) {
        @if map-has-key($value, "color") {
          $value: map-get($value, "color");
        }
      }

      @if $color-variant == "regular" {
        $selector: "#{$color}";
      } @else {
        $selector: "#{$color}-#{$color-variant}";
      }

      &--#{$selector} {
        @content;
      }

    }
  }
}
