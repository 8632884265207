
/* --------------------------------------------------------------------------
   Functions
   -------------------------------------------------------------------------- */


@function is-map($var) {
  @return type-of($var) == map;
}

@function is-color($var) {
  @return type-of($var) == color;
}



@function is-color-light($color) {
  @return (.2126*red($color) + .7152*green($color) + .0722*blue($color)) / 255 > .5;
}


@function tint($color, $percent) {
  @if is-color($color) {
    @return mix(white, $color, $percent);
  } @else {
    @error "⚠️  Invalid color `#{$color}`.";
  }
}

@function shade($color, $percent) {
  @if is-color($color) {
    @return mix(black, $color, $percent);
  } @else {
    @error "⚠️  Invalid color `#{$color}`.";
  }
}


@function color-dark($color) {
  @if is-color($color) {
    @return shade($color, 60%);
  } @else {
    @error "⚠️  Invalid color `#{$color}`.";
  }
}
@function color-light($color) {
  @if is-color($color) {
    @return tint(saturate($color, 5%), 25%);
  } @else {
    @error "⚠️  Invalid color `#{$color}`.";
  }
}
@function color-lighter($color) {
  @if is-color($color) {
    @return tint(saturate($color, 10%), 50%);
  } @else {
    @error "⚠️  Invalid color `#{$color}`.";
  }
}




@function rem($pixels, $context: $font-size-root) {
  // @if (unitless($pixels)) {
  //   $pixels: $pixels * 1px;
  // }

  // @if (unitless($context)) {
  //   $context: $context * 1px;
  // }

  @return ($pixels / $context) * 1rem;
}



@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function encode-hex($color) {
  @return str-replace(unquote("#{$color}"), "#", "%23");
}