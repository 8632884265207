
/* --------------------------------------------------------------------------
   Stylesheet
   -------------------------------------------------------------------------- */

// import dependent variables and functions
@import "../smeo-styles/scss/functions/functions";
@import "../smeo-styles/scss/functions/color";
@import "../smeo-styles/scss/globals/colors";



/* Body
   -------------------------------------------------------------------------- */

$body-color: color("black");
$body-bg: color("white", dark);


/* Typography
   -------------------------------------------------------------------------- */

   $font-path: "/assets/fonts" !default;


/* Modal
   -------------------------------------------------------------------------- */

$modal-width-sm: 400px;
$modal-width-md: 640px;
$modal-width-lg: 800px;

$modal-padding-sm: 1.5rem;
$modal-padding-md: 2rem;
$modal-padding-lg: 2.5rem;

/* Progress bar
   -------------------------------------------------------------------------- */

$progress-width: .5rem;


/* Timeline
   -------------------------------------------------------------------------- */

$invoice-timeline-width-md: 280px;
$invoice-timeline-width-xl: 360px;
$invoice-timeline-width-xxl: 440px;
