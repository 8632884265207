
/* --------------------------------------------------------------------------
   Button
   -------------------------------------------------------------------------- */


/* Button variant mixin
   -------------------------------------------------------------------------- */

@mixin ui-button-variant($name, $background, $color: false) {

  @if not $color {
    @if is-color-light(color($background)) {
      $color: color("black");
    } @else {
      $color: color("white");
    }
  }

  $border: $background;

  .ui-button--#{$name},
  .ui-nav-item.ui-button--#{$name} {
    color: color($color);
    background-color: color($background);
    border-color: color($border);

    &:focus {
      color: color($color);
      box-shadow: 0 0 0 3px rgba(tint(color($background), 50%), 50%);
    }
    &:hover,
    &.is-selected {
      color: color($color);
      background-color: shade(color($background), 10%);
      border-color: shade(color($border), 10%);
      box-shadow: none;
    }
    @include active {
      color: color($color);
      background-color: shade(color($background), 15%);
      border-color: shade(color($border), 20%);
    }
    &.ui-button--dark {
      background-color: color($background, dark);
      border-color: color($border, dark);
    }
    &.ui-button--outline {
      background-color: transparent;
      color: color($background);

      &.ui-button--dark {
        color: color($background, dark);
      }
      &:hover {
        background-color: color($background);
        border-color: color($background);

        @if is-color-light(color($background)) {
          color: color("black");
        } @else {
          color: color("white");
        }

        &.ui-button--dark {
          background-color: color($background, dark);
          border-color: color($background, dark);
        }
      }
      &:active,
      &.is-active,
      &.is-selected {
        background-color: shade(color($background), 10%);
        border-color: shade(color($border), 10%);
        color: color("white");

        &.ui-button--dark {
          background-color: shade(color($background, dark), 10%);
          border-color: shade(color($border, dark), 10%);
        }
      }
      &.is-plain {
        border-color: color("gray", light);
        color: color("black", light);

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-selected {
          background-color: transparent;
          border-color: color($background);
          color: color($background);
        }
        &:active,
        &.is-active,
        &.is-selected {
          background-color: rgba(color($background), .1);
        }
      }
    }
  }

}


/* Base styles
   -------------------------------------------------------------------------- */

.ui-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-image: none;
  outline: none;
  user-select: none;
  vertical-align: middle;
  line-height: 1.125;
  white-space: nowrap;
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  padding: $button-padding-md-y $button-padding-md-x;
  background: none;
  border: $button-border-width solid color("gray", light);
  border-radius: $button-border-radius-md;
  color: color("black", light);
  transition: $ui-button-transition;
  font-weight: 500;
  text-align: center;

  // default size
  @include ui-button--md;

  &:focus {
    //box-shadow: 0 0 0 3px rgba(tint(color("gray", light), 50%), 50%);
    z-index: 1;
    text-decoration: none;
  }
  &:hover {
    box-shadow: none;
    //box-shadow: 0 4px 6px rgba(color("black", light), .1), 0 1px 3px rgba(black, .08);
  }
  @include disabled {
    //opacity: .5;
    cursor: not-allowed;

    &,
    &:focus,
    &:hover,
    &:active {
      background-color: color("gray", lightest) !important;
      border-color: color("gray", light) !important;
      color: color("gray", light) !important;

      &.ui-button--text {
        background-color: transparent !important;
      }
    }
  }
  + .ui-button {
    margin-left: $spacer-2;

    .ui-button-block & {
      margin-left: $spacer-3;
    }
  }

  .text-muted {
    color: inherit;
    opacity: .75;
  }

  @include media-breakpoint-down('xs') {
    display: block;
    width: 100%;

    + .ui-button {
      margin-left: 0;
    }
    .ui-button-group & {
      display: inline-block;
      width: auto;
    }
  }
}
*:not(.ui-button-block) > .ui-button + .ui-button,
.ui-button-block + .ui-button {
  @include media-breakpoint-down('xs') {
    margin-top: $spacer-2;
  }
}


/* Button color variants
   -------------------------------------------------------------------------- */

@each $color, $color-value in $colors {
  @each $color-variant, $value in $color-value {
    $selector: null;

    @if is-map($value) {
      @if map-has-key($value, "color") {
        $value: map-get($value, "color");
      }
    }

    @if $color-variant == "regular" {
      $selector: "#{$color}";
    } @else {
      $selector: "#{$color}-#{$color-variant}";
    }

    @include ui-button-variant($selector, color($color, $color-variant));
  }
}

@include ui-button-variant('primary', primary, white);
@include ui-button-variant('secondary', purple, white);

@include ui-button-variant('success', success, white);
@include ui-button-variant('attention', attention, white);
@include ui-button-variant('danger', danger, white);
@include ui-button-variant('info', info, white);


/* Sizing
   -------------------------------------------------------------------------- */

.ui-button--block {
  display: block;
  width: 100%;
}

.ui-button--xs {
  @include ui-button--xs;
}
.ui-button--sm {
  @include ui-button--sm;
}
.ui-button--md {
  @include ui-button--md;
}
.ui-button--lg {
  @include ui-button--lg;
}
.ui-button--xl {
  @include ui-button--xl;
}


/* Button groups
   -------------------------------------------------------------------------- */

.ui-button-block.ui-button-block--flex {
  display: flex;

  .ui-button {
    flex: 1 1 0;
  }
}


/* Button groups
   -------------------------------------------------------------------------- */

// Make the div behave like a button
.ui-button-group,
.ui-button-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .ui-button alignment given font-size hack above

  > .ui-button {
    position: relative;
    flex: 0 1 auto;

    // Bring the hover, focused, and "active" buttons to the fron to overlay
    // the borders properly
    @include hover {
      z-index: 2;
    }
    &:focus,
    &:active,
    &.is-selected {
      z-index: 2;
    }
  }

  // Prevent double borders when buttons are next to each other
  .ui-button + .ui-button,
  .ui-button + .ui-button-group,
  .ui-button-group + .ui-button,
  .ui-button-group + .ui-button-group {
    margin-left: -$button-border-width;

    &.ui-button--xs,
    &.ui-button--sm {
      margin-left: -$border-width;
    }

    &:not(.ui-button--outline):not(.is-plain) {
      margin-left: 0;

      &::before {
        content: "";
        position: absolute;
        z-index: 3;
        width: $border-width;
        left: -$button-border-width;
        top: -$button-border-width;
        bottom: -$button-border-width;
        background-color: rgba(color("white"), .25);
      }
    }
  }
  .ui-button:focus {
    &, + .ui-button {
      &::before {
        background-color: transparent;
      }
    }
  }
}
.ui-button-group-block {
  width: 100%;

  > .ui-button {
    flex-grow: 1;
  }
}

// Optional: Group multiple button groups together for a toolbar
.ui-button-toolbar {
  display: flex;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

.ui-button-group > .ui-button:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

// Set corners individual because sometimes a single button can be in a .ui-button-group and we need :first-child and :last-child to both match
.ui-button-group > .ui-button:first-child {
  margin-left: 0;

  &:not(:last-child):not(.dropdown-toggle) {
    @include border-right-radius(0);
  }
}
// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.ui-button-group > .ui-button:last-child:not(:first-child),
.ui-button-group > .dropdown-toggle:not(:first-child) {
  @include border-left-radius(0);
}

// Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
.ui-button-group > .ui-button-group {
  float: left;
}
.ui-button-group > .ui-button-group:not(:first-child):not(:last-child) > .ui-button {
  border-radius: 0;
}
.ui-button-group > .ui-button-group:first-child:not(:last-child) {
  > .ui-button:last-child,
  > .dropdown-toggle {
    @include border-right-radius(0);
  }
}
.ui-button-group > .ui-button-group:last-child:not(:first-child) > .ui-button:first-child {
  @include border-left-radius(0);
}

// On active and open, don't show outline
.ui-button-group .dropdown-toggle:active,
.ui-button-group.open .dropdown-toggle {
  outline: 0;
}


// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.ui-button-group-sm > .ui-button { @extend .ui-button--sm; }
.ui-button-group-lg > .ui-button { @extend .ui-button--lg; }


//
// Split button dropdowns
//

.ui-button + .dropdown-toggle-split {
  padding-right: $button-padding-md-x * .75;
  padding-left: $button-padding-md-x * .75;

  &::after {
    margin-left: 0;
  }
}

.ui-button--sm + .dropdown-toggle-split {
  padding-right: $button-padding-sm-x * .75;
  padding-left: $button-padding-sm-x * .75;
}
.ui-button--lg + .dropdown-toggle-split {
  padding-right: $button-padding-lg-x * .75;
  padding-left: $button-padding-lg-x * .75;
}

.ui-button-group {
  > .ui-button,
  > .ui-button-group > .ui-button {
    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0,0,0,0);
      pointer-events: none;
    }
  }
}


// Variants
.ui-button--default {
  &:focus {
    box-shadow: 0 0 0 3px rgba(tint(color(primary), 50%), 50%);
  }
  &:hover,
  &:active,
  &.is-active {
    border-color: color(primary);
    color: color(primary);
    box-shadow: none;
  }
  &:active {
    background-color: rgba(color(primary), .1);
  }
}

.ui-button--text {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border-color: transparent;

  &:focus {
    text-decoration: underline;
  }
  &:hover,
  &:focus,
  &:active {
    color: color(primary);
  }
  &:hover,
  &:focus {
    color: shade(color(primary), 10%);
  }
  &:disabled,
  &.is-disabled {
    &,
    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: color("gray", light);
    }
  }
}

.ui-icon-button {
  @include button-reset;
}




// Grouped buttons
.ui-button-block {
  margin-bottom: -$spacer-3;

  .ui-button {
    margin-bottom: $spacer-3;
  }
}



/* Action link button
   -------------------------------------------------------------------------- */

.ui-action-button {
  @include button-reset;
  display: inline-block;
  font-weight: 500;
  transition: $transition;

  @include hover {
    color: color(primary);
  }
  &:disabled,
  &.is-disabled {
    text-decoration: none !important;
  }

  .ui-ico--ball {
    &, &:hover {
      background-color: currentColor;
      color: inherit;

      svg {
        color: color("white");
      }
    }
  }

  &--sm {
    font-size: $font-size-sm;
    font-weight: 400;

    @include icon-first-child() {
      margin-right: 0;
    }
    @include icon-last-child() {
      margin-left: 0;
    }
  }

  &--gray {
    color: color("gray", light);

    @include hover {
      color: color("gray");
    }
  }

  &--blue {
    color: color(primary);

    @include hover {
      color: color(primary, dark);
    }
  }

  &--blue-dark {
    color: color("blue", dark);

    @include hover {
      color: color("black");
    }
  }

  &--red-dark {
    color: color("red", dark);

    @include hover {
      color: color("black");
    }
  }
}


/* Responsive floated action button
   -------------------------------------------------------------------------- */

@keyframes action-button-show {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@mixin action-button {
  @include button-reset;

  position: fixed;
  z-index: 98 !important;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  background-color: color("white");
  color: color("gray");
  border: 0;
  transition: background-color #{$transition-speed} #{$transition-ease};

  opacity: 0;
  animation: action-button-show .5s .5s;
  animation-fill-mode: forwards;

  // iPhone X safe areas
  @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
    right: unquote("max(1.5rem, env(safe-area-inset-right))");
    bottom: unquote("max(1.5rem, env(safe-area-inset-bottom))");
  }

  &, &:hover {
    box-shadow: 0 1px 8px 0 rgba(black, .2);
  }

  // Hide text
  > span {
    position: absolute;
    left: -9999px;
    z-index: -1;
    opacity: 0;
  }

  .ui-ico {
    @include center-both(absolute);
    line-height: 0;

    &--add svg {
      width: 20px;
      height: 20px;
    }
    &--close svg {
      width: 16px;
      height: 16px;
    }
  }

  @include hover {
    background-color: color("white", dark);
    color: color("gray");
  }

  .count {
    position: absolute;
    z-index: 1;
    top: -.25rem;
    right: -.25rem;
    margin-top: 0;

    &.ui-label--attention {
      background-color: color(attention, dark);
    }
  }
}

.action-button {
  @include action-button;

  .ui-ico {
    margin: 0 !important;
  }
}

.action-button--gray {
  background-color: color("gray");
  color: color("white");

  @include hover {
    background-color: shade(color("gray"), 10%);
    color: color("white");
  }
}

@mixin action-button--primary {
  &, &.ui-button {
    background-color: color("white");
    color: color(primary);

    @include hover {
      background-color: color("white", dark);
      color: color(primary);
    }
  }
}
.action-button--primary {
  @include action-button--primary;
}

.action-button--attention {
  background-color: color(attention);
  color: color("white");

  &, &:hover {
    box-shadow: 0 1px 15px 0 rgba(color(attention, light), .75),
                0 1px 4px 0 rgba(color(attention, dark), .25);
  }

  @include hover {
    background-color: shade(color(attention), 10%);
    color: color("white");

    &, &:hover {
      box-shadow: 0 1px 15px 0 rgba(shade(color(attention, light), 10), .75),
                  0 1px 4px 0 rgba(color(attention, dark), .25);
    }
  }
}
