
/* --------------------------------------------------------------------------
   Mixins
   -------------------------------------------------------------------------- */


@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}


@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin hover {
  &:hover,
  &:focus {
    @content;
  }
}
@mixin active {
  &:active,
  &.is-active {
    @content;
  }
}
@mixin disabled {
  &:disabled,
  &.is-disabled {
    @content;
  }
}


@mixin text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



@mixin border-top-radius($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}


@mixin center-horizontal($position: relative) {
  position: $position;
  left: 50%;
  transform: translateX(-50%);
}
@mixin center-vertical($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}
@mixin center-both($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@mixin bg-icon($name, $width: false, $height: false, $boxed: false) {
  @if $boxed {
    width: $width;
    height: $height;
  }
  background-image: $name;
  @if $width and $height {
    background-size: $width $height;
    background-repeat: no-repeat;
    background-position: center center;
  }
}


@mixin button-reset() {
  width: auto;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none !important;
  box-shadow: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: inherit;
  text-decoration: none;
  text-shadow: none;
  color: inherit;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  user-select: text;
}
@mixin list-unstyled() {
  margin: 0;
  padding: 0;
  list-style: none;
}
@mixin input-hidden() {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
@mixin inline-spacing-fix() {
  font-size: 0;

  > * {
    font-size: $font-size-base;
  }
}


@mixin icon-first-child() {
  icon:first-child:not(:last-child) .ui-ico {
    @content;
  }
}
@mixin icon-last-child() {
  icon:last-child:not(:first-child) .ui-ico {
    @content;
  }
}


@mixin action-element($parent: false) {
  @if not $parent {
    .ui-button,
    .action-link,
    button,
    a {
      @content;
    }
  } @else {
    .ui-button #{$parent},
    .action-link #{$parent},
    button #{$parent},
    a #{$parent} {
      @content;
    }
  }
}



@function fifth-elem-bg($color) {
  @return url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cpath d='M100,100A100,100,0,0,1,0,0H100Z' fill='#{encode-hex($color)}'/%3E%3C/svg%3E");
}

@mixin fifth-elem($radius, $color: color("gray", lightest)) {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  width: $radius * 2;
  background-image: fifth-elem-bg($color);
  background-size: 100% 100%;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.fifth-elem {
  @include fifth-elem(160px);

  &--50 {
    width: 50%;
  }

  ~ * {
    position: relative;
  }
}

@mixin fifth-elem-variant($name, $color) {
  .fifth-elem--#{$name} {
    background-image: fifth-elem-bg($color)
  }
}
@include fifth-elem-variant('blue-light', color("blue", light));
@include fifth-elem-variant('petrol-light', color("petrol", light));
@include fifth-elem-variant('purple-light', color("purple", light));
@include fifth-elem-variant('red-light', color("red", light));
