/* --------------------------------------------------------------------------
   Colors
   -------------------------------------------------------------------------- */

$colors: (
  "black": (
    regular: #000000,
    light: #504f4f
  ),
  "gray": (
    regular: #7c7a7a,
    light: #aba8a6,
    lighter: #dbd7d2,
    lightest: #e8e5e5
  ),
  "white": (
    darker: #f3f2f2,
    dark: #f9f8f8,
    regular: #ffffff
  ),
  "petrol": (
    regular: (
      color: #00a0a6,
      contrast: #ffffff
    ),
    dark: (
      color: #005969,
      contrast: #ffffff
    ),
    light: (
      color: #c7e4e2,
      contrast: #000000
    )
  ),
  "blue": (
    regular: (
      color: #007ccb,
      contrast: #ffffff
    ),
    dark: (
      color: #1d527f,
      contrast: #ffffff
    ),
    light: (
      color: #bfe2f4,
      contrast: #000000
    )
  ),
  "purple": (
    regular: (
      color: #8e5c96,
      contrast: #ffffff
    ),
    dark: (
      color: #634274,
      contrast: #ffffff
    ),
    light: (
      color: #edd0e1,
      contrast: #000000
    )
  ),
  "red": (
    regular: (
      color: #ea4278,
      contrast: #ffffff
    ),
    dark: (
      color: #993062,
      contrast: #ffffff
    ),
    light: (
      color: #fbcfc9,
      contrast: #000000
    )
  ),
  "green": (
    regular: (
      color: #00ab33,
      contrast: #ffffff
    ),
    dark: (
      color: #1d7f2d,
      contrast: #ffffff
    ),
    light: (
      color: #bff4cb,
      contrast: #000000
    )
  )
);

$contextual-colors: (
  "info": "purple",
  "success": "petrol",
  "fullypaid": "green",
  "attention": "red",
  "danger": #cc1418,
  "primary": "blue",
  "secondary": "purple"
);
