
/* --------------------------------------------------------------------------
   Icon
   -------------------------------------------------------------------------- */


.ui-ico {
  display: inline-block;

  svg {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    transition:
      color #{$transition-speed} #{$transition-ease},
      background-color #{$transition-speed} #{$transition-ease};

    .ui-button &,
    .action-link &,
    a &,
    button & {
      transition: none;
    }
  }
}
button.ui-ico {
  @include button-reset;
  min-width: 1.5rem;
  cursor: pointer;
}

@include icon-first-child() {
  margin-right: .25rem;
}
@include icon-last-child() {
  margin-left: .25rem;
}

@mixin ui-ico-ball-variant($name, $background, $color) {
  .ui-ico--ball.ui-ico--ball--#{$name} {
    background-color: $background;
    color: $color;
  }
  a, button {
    @include hover {
      .ui-ico--ball.ui-ico--ball--#{$name} {
        background-color: shade($background, 10%);
      }
    }
    @include active {
      .ui-ico--ball.ui-ico--ball--#{$name} {
        background-color: shade($background, 15%);
      }
    }
    &:disabled,
    &.is-disabled {
      .ui-ico--ball.ui-ico--ball--#{$name} {
        background-color: $background;
      }
    }
  }
}

.ui-ico--ball {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  background-color: color("gray", lighter);
  color: color("black");
  border-radius: 9999px;
  vertical-align: middle;
  text-align: center;
  transition: background-color #{$transition-speed} #{$transition-ease};

  a &,
  button & {
    transition: background-color #{$transition-speed} #{$transition-ease},
                transform #{$transition-speed} #{$transition-ease};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.ui-ico--ball--sm  {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
  }
}
a, button {
  .ui-ico--arrow-left,
  .ui-ico--arrow-right {
    transition: transform #{$transition-speed} #{$transition-ease};
  }
  @include hover {
    .ui-ico--ball {
      transform: scale(1.1);
      //background-color: color("gray");
    }
    .ui-ico--arrow-left {
      transform: translateX(-.25em);
    }
    .ui-ico--arrow-right {
      transform: translateX(.25em);
    }
  }
  @include active {
    .ui-ico--ball {
      //background-color: shade(color("gray"), 5%);
    }
  }
  &:disabled,
  &.is-disabled {
    .ui-ico--ball {
      transform: none !important;
      opacity: .5;
    }
  }
}

@include ui-ico-ball-variant('primary', color(primary), color("white"));
@include ui-ico-ball-variant('secondary', color(primary), color("white"));
@include ui-ico-ball-variant('tertiary', color(primary), color("white"));

@include ui-ico-ball-variant('blue', color("blue"), color("white"));
@include ui-ico-ball-variant('petrol', color("petrol"), color("white"));
@include ui-ico-ball-variant('purple', color("purple"), color("white"));
@include ui-ico-ball-variant('red', color("red"), color("white"));

@include ui-ico-ball-variant('success', color(success), color("white"));
@include ui-ico-ball-variant('attention', color(attention), color("white"));
@include ui-ico-ball-variant('danger', color(danger), color("white"));
@include ui-ico-ball-variant('info', color(info), color("white"));

@include ui-ico-ball-variant('gray', color("gray"), color("white"));
@include ui-ico-ball-variant('light', color("white"), color("black", light));



.ui-icon--boxed {
  display: inline-flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}


.ui-ico--add {
  &.ui-ico--sm svg {
    width: 9px;
    height: 9px;
  }
  &.ui-ico--lg svg {
    width: 20px;
    height: 20px;
  }
}

.ui-ico--download {
  &.ui-ico--sm {
    .ui-button & {
      margin-top: -1px;
      margin-bottom: -1px;
    }
  }
}
@include action-element {
  .ui-ico--angle-down {
    transition: transform #{$transition-speed} #{$transition-ease};
  }
  &.is-expanded,
  .is-expanded & {
    .ui-ico--angle-down {
      transform: rotate(-180deg);
    }
  }
}


.ui-ico--angle-left,
.ui-ico--angle-right {
  &.ui-ico--sm svg {
    width: 5px;
    height: 8px;
  }
}


.ui-ico--refresh {
  .ui-button--xs & {
    margin-bottom: -1px !important;
  }
}
@include action-element {
  .ui-ico--refresh svg {
    transition: transform .5s cubic-bezier(.645, .045, .355, 1);
  }
  &:hover .ui-ico--refresh svg {
    transform: rotate(360deg);
  }
}

.ui-ico--building,
.ui-ico--person {
  &.ui-ico--sm svg {
    width: 16px;
    height: 16px;
  }
}


.intrum-mark {
  display: block;
}

.ui-ico--intrum-badge svg {
  margin-top: -1px;
}


.ui-ico--apple,
.ui-ico--google,
.ui-ico--android {
  svg {
    vertical-align: baseline;
  }
}
.ui-ico--google-lens {
  color: color("gray");
}

@keyframes disco {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
.has-discofever {
  animation: disco 1s infinite;
  animation-timing-function: linear;
}


.qr-container {
  pointer-events: none;
}
