/* --------------------------------------------------------------------------
   Stylesheet
   -------------------------------------------------------------------------- */

/* Variables
   -------------------------------------------------------------------------- */

// import application variables
@import "assets/sass/globals/_variables.scss";
// import base styles
@import "assets/sass/smeo-styles/scss/styles.scss";

/* Globals
   -------------------------------------------------------------------------- */

@import "assets/sass/globals/_fonts.scss";

/* Globals
   -------------------------------------------------------------------------- */

@import "assets/sass/globals/_base.scss";

/* Collection
   -------------------------------------------------------------------------- */

@import "assets/sass/collection/_progress.scss";

/* Modules
   -------------------------------------------------------------------------- */

@import "assets/sass/modules/_app.scss";
@import "assets/sass/modules/_page.scss";
@import "assets/sass/modules/_header.scss";
@import "assets/sass/modules/_footer.scss";
@import "assets/sass/modules/_sidebar.scss";
@import "assets/sass/modules/_backdrop.scss";
@import "assets/sass/modules/_modal.scss";

/* Pages
   -------------------------------------------------------------------------- */

@import "assets/sass/pages/_invoices.scss";
@import "assets/sass/pages/_cases.scss";
@import "assets/sass/pages/_customers.scss";
@import "assets/sass/pages/_articles.scss";
@import "assets/sass/pages/_reports.scss";
@import "assets/sass/pages/_onboarding.scss";

/* Print
   -------------------------------------------------------------------------- */

@import "assets/sass/globals/_print.scss";
