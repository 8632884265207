
// ====================================
// Sidebar
// ====================================


.page-sidebar {
  width: 14rem;
  max-width: 100%;
  overflow: hidden;
  z-index: $z-sidebar-sm;
  background: color("white", darker);
  box-shadow: $border-width 0 0 0 rgba(black, .1);

  @include media-breakpoint-down('md') {
    position: fixed;
    top: 4.25rem;
    bottom: 0;
    left: 0;
  }
  @include media-breakpoint-up('md') {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
  }
  @include media-breakpoint-up('lg') {
    z-index: $z-sidebar;
  }
  @include media-breakpoint-up('xl') {
    width: 15rem;
  }
}


.page-sidebar-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  overflow-y: auto;
  //z-index: 99;
  background: color("white", darker);
  box-shadow: $border-width 0 0 0 rgba(black, .1);

  @supports(padding: max(0px)) {
    padding-left: unquote("max(1.25rem, env(safe-area-inset-left))");
  }

  .sidebar-navigation {
    flex-grow: 1;
  }

  .sidebar-sticky-bottom {
    margin-left: -.25rem;
    margin-right: -.25rem;

    @include media-breakpoint-up('md') {
      position: absolute;
      left: .75rem;
      right: .75rem;
      bottom: .75rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .header-search {
    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  hr {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .header-search {
    position: relative;
    //padding: 0 .75rem 0 1rem;

    > .ui-form-group {
      //width: 100%;
    }

    .ui-ico--ij-search {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -28px *.5;
      color: color(primary, light);
      transition: transform .25s #{$transition-ease}, color .2s #{$transition-ease}, opacity .15s #{$transition-ease};
      opacity: 1;
    }
    .ui-input {
      label {
        display: block;
        cursor: text;
        margin: 0;
      }
    }
    .ui-input--inner {
      padding: .8125rem .75rem;
      padding-left: rem(28px) + .5rem;
      //border: none;
      font-weight: 500;
      transition: $transition, padding-left #{$transition-speed} #{$transition-ease};

      @include placeholder {
        color: color(primary, light);
      }
      &:focus {
        padding-left: .75rem;

        @include placeholder {
          color: color(primary, light);
        }

        ~ wi-icon .ui-ico--ij-search {
          transform: translateX(-100%);
          cursor: auto;
          color: color(primary, light);
          opacity: 0;
        }
      }
    }
  }

  #sidebar-nav {
    .ui-nav-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .ui-label {
        float: right;
        margin-top: .125rem;
      }
      .ui-ico {
        margin-right: $spacer-3;
        text-align: center;
      }
    }
  }

  .support {
    display: block;
    font-weight: 500;
    color: color("black", light);

    .ui-card {
      transition: $transition;
    }
    &:hover {
      .ui-card {
        box-shadow: 0 2px 4px 0 rgba(black, .05);
      }
    }
    &:focus {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
    &:active {
      .ui-card {
        box-shadow: 0 1px 2px 0 rgba(black, .1);
      }
    }

    span {
      vertical-align: middle;
    }
  }

  .account {
    @include media-breakpoint-up('md') {
      display: none;
    }

    .ui-nav-item {
      font-weight: 500;
    }
    #logoutButtonSidebar {
      width: 100%;
      margin-top: .75rem;
      background-color: color("gray", lightest);
    }
  }

  .free-month-alert {
    .ui-action-button {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
  }
}
