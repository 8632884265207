
/* --------------------------------------------------------------------------
   Helper
   -------------------------------------------------------------------------- */


// Floats

@mixin float-left {
  float: left !important;
}
@mixin float-right {
  float: right !important;
}
@mixin float-none {
  float: none !important;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .float#{$infix}-left  { @include float-left; }
    .float#{$infix}-right { @include float-right; }
    .float#{$infix}-none  { @include float-none; }
  }
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}



// Responsive visibility utilities

@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      display: none !important;
    }
  }
  .hidden-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      display: none !important;
    }
  }
}

.hidden {
  display: none;
}



.row.v-spacing {
  &,
  &:last-child {
    margin-bottom: -$grid-gutter-width / 2;
  }

  %grid-column {
    margin-bottom: $grid-gutter-width / 2;
  }
  @each $breakpoint in map-keys($grid-breakpoints) {
    .col-#{$breakpoint} {
      @extend %grid-column;
    }
    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        @extend %grid-column;
      }
    }
  }
}


.clearfix {
  @include clearfix;
}


.pos-relative {
  position: relative;
}


.is-disabled {
  cursor: not-allowed;
}
.is-disabled-section {
  position: relative;
  opacity: .25;
  cursor: not-allowed;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: not-allowed;
  }
}

.bg-contrast {
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: rgba($body-bg, .5);
    top: .5rem;
    right: .5rem;
    bottom: .5rem;
    left: .5rem;
    filter: blur(32px);
  }
}
