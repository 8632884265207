.modal-host {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: $z-modal;

  &.is-visible {
    pointer-events: auto;
  }
}

.modal-host__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  // padding: 0 1rem;
  min-height: 100%;
  width: 100%;
  // pointer-events: none;
}

.modal {
  position: relative;
  overflow: visible;
  max-width: 100%;
  background: color("white");
  box-shadow: 0 2px 8px 0 rgba(black, .15);

  &--sm {
    width: $modal-width-sm;

    .modal__block {
      padding: $modal-padding-sm;
    }
  }
  &, &--md {
    width: $modal-width-md;

    .modal__block {
      padding: $modal-padding-sm;

      @include media-breakpoint-up('md') {
        padding: $modal-padding-md;
      }
    }
  }
  &--lg {
    width: $modal-width-lg;

    .modal__block {
      padding: $modal-padding-sm;

      @include media-breakpoint-up('md') {
        padding: $modal-padding-md;
      }
      @include media-breakpoint-up('lg') {
        padding: $modal-padding-lg;
      }
    }
  }

  &--center {
    align-self: center;
  }
  &--right {
    flex: auto 1 0;
    align-self: flex-end;
    height: 100%;
  }
  &--left {
    align-self: flex-start;
    height: 100%;
  }

}
