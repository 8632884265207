/* --------------------------------------------------------------------------
   Label
   -------------------------------------------------------------------------- */

@mixin ui-label-variant($name, $background, $color) {
  .ui-label--#{$name} {
    color: color($color);
    background-color: color($background);

    @if not is-color-light(color($background)) {
      &.ui-label--muted {
        color: color($background, dark);
        background-color: color($background, light);
      }
    }
  }
}

.ui-label {
  display: inline-block;
  white-space: nowrap;

  box-sizing: border-box;
  margin: 0;
  padding: 0 $label-padding-md-x;
  min-width: 1.25rem;
  max-width: 100%;

  background: transparent;
  border-radius: $label-border-radius;
  color: color("black");
  transition: $transition;

  line-height: 1.428571429em;
  font-family: $font-family-base;
  font-size: 0.875em;
  font-weight: 500;
  text-align: center;

  + .ui-label {
    margin-left: 0.25rem;
  }

  &--pill {
    border-radius: 9999px;
    padding-left: $label-padding-pill-md-x;
    padding-right: $label-padding-pill-md-x;

    &.count {
      padding-left: $label-padding-pill-sm-x;
      padding-right: $label-padding-pill-sm-x;
    }
  }
}

// Sizing

// .ui-label--xs {
//   padding: $label-padding-xs-y $label-padding-xs-x;
//   border-radius: $label-border-radius-xs;
//   font-family: $font-family-base;
//   font-size: $font-size-sm;
//   font-weight: 500;
// }
// .ui-label--sm {
//   padding: $label-padding-sm-y $label-padding-sm-x;
//   border-radius: $label-border-radius-sm;
// }
// .ui-label--lg {
//   padding: $label-padding-lg-y $label-padding-lg-x;
//   border-width: $border-width-thicker;
//   border-radius: $label-border-radius-lg;
// }

// Variants

@include ui-label-variant("primary", color("primary"), color("white"));
@include ui-label-variant("secondary", color("purple"), color("white"));
@include ui-label-variant("tertiary", color("blue"), color("white"));

@include ui-label-variant("info", color(info), color("white"));
@include ui-label-variant("success", color(success), color("white"));
@include ui-label-variant("attention", color(attention), color("white"));
@include ui-label-variant("danger", color(danger), color("white"));

@include ui-label-variant("gray", color("gray"), color("white"));
@include ui-label-variant("light", color("white"), color("black", light));

@include ui-label-variant("blue", "blue", color("white"));
@include ui-label-variant("purple", "purple", color("white"));
@include ui-label-variant("petrol", "petrol", color("white"));
@include ui-label-variant("red", "red", color("white"));
@include ui-label-variant("green", "green", color("white"));
