
/* --------------------------------------------------------------------------
   Reports
   -------------------------------------------------------------------------- */


/* Report types page
   -------------------------------------------------------------------------- */

.report-card {
  @include clearfix;

  .show-link {
    float: right;
    margin-top: -1.375rem;
    font-weight: 500;
  }
}


/* Report list
   -------------------------------------------------------------------------- */

.period-group {
  margin-bottom: $spacer-md;
}
.period-group-title {
  margin-bottom: 1.25rem;
  padding-bottom: .25rem;
  border-bottom: $border-width solid color("gray", light);
  @extend .lead;
}
.period-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .5rem -1rem 0 -1rem;
  padding: .5rem 1rem;
  border-radius: $border-radius-md;
  border: $card-border-width solid transparent;
  transition: $transition;

  @include hover {
    border-color: $card-border-color;
  }
  &:focus {
    text-decoration: none;
  }
  &.is-unread {
    background-color: color("white");
    border-color: $card-border-color;
  }
  &.is-empty {
    .period-title {
      color: color("gray");
    }
  }
}
.period-title {
  margin-bottom: 0;
  color: color(primary, dark);

  .period-id {
    margin-right: .25rem;
    font-weight: 400;
    color: color("gray", light);
  }
}
.period-pagination {
  display: flex;
  justify-content: space-between;
  color: color(primary, dark);

  header & {
    margin-bottom: $spacer-lg;
    padding-bottom: $spacer-sm;
    border-bottom: $border-width solid color("gray", lighter);
  }
  footer & {
    margin-top: $spacer-xxl;
  }
}
.end-of-periods {
  @include media-breakpoint-up('md') {
    text-align: center;
  }
}


/* Report page
   -------------------------------------------------------------------------- */

.report-nav {
  margin-bottom: 1rem;

  @include media-breakpoint-up('md') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  h2 {
    margin-bottom: 0;
  }
  .print-button {
    @include media-breakpoint-down('sm') {
      margin-top: .75rem;
    }
  }
}
.report-section {
  margin-bottom: $spacer-lg;
}
.report-table {
  margin: 0 -1rem;
  padding: .75rem 1rem;
  background-color: rgba(color(primary, light), .2);

  > *:last-child {
    margin-bottom: 0;
  }

  .ui-table__list-item:last-child,
  .ui-table__list-item-group:last-child {
    border-bottom: none;
  }

  .ui-table--striped {
    .ui-table__list-item:not(.ui-table__list-item-group) {
      &:nth-child(2n) {
        background-color: rgba(color(info, dark), .05);
      }
    }
    .ui-table__header ~ .ui-table__list-item:not(.ui-table__list-item-group) {
      &:nth-child(2n+1) {
        background-color: rgba(color(info, dark), .05);
      }
    }
  }
}
.report-summary-table {
  @include media-breakpoint-up('md') {
    width: 90%;
    max-width: 940px;
  }
  @include media-breakpoint-up('xl') {
    width: 80%;
  }
}


/* Accounts recievable
   -------------------------------------------------------------------------- */

.list-accounts-receivable-items {

  .ui-table__header,
  .ui-table__list-item {
    align-items: flex-start;
  }

  .col--id {
    order: 1;

    @include media-breakpoint-up('md') {
      flex-basis: 80px;
      flex-shrink: 0;
    }
  }
  .col--date {
    order: 2;

    @include media-breakpoint-up('md') {
      flex-basis: 120px;
      flex-shrink: 0;
    }

     &.ui-table__list-item-col .col-value {
      font-weight: 500;
    }
  }
  .col--amount {
    order: 9;
    text-align: left;

    @include media-breakpoint-up('md') {
      flex-basis: 20%;
      text-align: right;
    }
  }
  .col--sum {
    order: 10;

    @include media-breakpoint-up('md') {
      flex-basis: 20%;
      text-align: right;
    }
  }

}


/* Payments
   -------------------------------------------------------------------------- */

.list-payment-items {

  .col--date {
    order: 1;

    @include media-breakpoint-up('md') {
      flex-basis: 120px;
      flex-shrink: 0;
    }

     &.ui-table__list-item-col .col-value {
      font-weight: 500;
    }
  }
  .col--invoice {
    order: 2;

    @include media-breakpoint-up('md') {
      flex-basis: 100px;
    }
  }
  .col--amount {
    order: 3;

    @include media-breakpoint-up('md') {
      flex-basis: 90px;
      flex-shrink: 0;
      text-align: right;
    }
  }
  .col--note {
    order: 4;

    @include media-breakpoint-up('md') {
      flex-basis: 50%;
    }
  }

}

.payment-summary {

  .col--note {
    flex-basis: auto;
  }
  .col--sum {
    flex-basis: auto;
  }

  .ui-table__list-item--footer {
    margin-top: $spacer-1;

    .col-title,
    .col-value {
      font-weight: 500;
    }

    @include media-breakpoint-down('sm') {
      .col--note {
        display: none;
      }
      .col--sum {
        flex-grow: 1;
      }
    }
  }

}


/* Print
   -------------------------------------------------------------------------- */

.report-print-meta {
  justify-content: space-between;
  margin-bottom: $spacer-xl;
  align-items: flex-end;
  display: none;

  h2 {
    margin-bottom: $spacer-2;
  }
  h4 {
    margin-bottom: .25rem;
  }
}

@media print {
  .report-print-meta {
    display: flex;
  }
  .period-pagination {
    display: none;
  }
  .report-table {
    background-color: transparent;
  }

}