html,
body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

#site-loader {
  @include center-both(absolute);

  svg {
    overflow: visible;
    border-radius: 20px;
    box-shadow: 0 0 0 rgba(color(primary), .4);
  }
}
