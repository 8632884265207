
/* --------------------------------------------------------------------------
   Pagination
   -------------------------------------------------------------------------- */


.ui-pagination {
  display: flex;
  font-size: 1rem;
  text-align: center;

  button {
    @include button-reset;
  }

  button,
  a {
    font-weight: 500;
    cursor: pointer;
    transition: $transition;
    color: color("black", light);

    &:hover {
      color: color(primary);
    }
    &.disabled {
      color: $text-muted;
      cursor: not-allowed;
    }
  }

  .ui-ico svg {
    vertical-align: baseline;
  }
  .btn-prev {
    margin-right: 1rem;

    .ui-ico--angle-left {
      margin-right: .25rem;
    }
  }
  .btn-next {
    margin-left: 1rem;

    .ui-ico--angle-right {
      margin-left: .25rem;
    }
  }

  .ui-pager.ui-pager--mobile {
    flex: 1;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  .ui-pager.ui-pager--mobile {
    flex-grow: 0;
  }
  .btn-prev {
    text-align: right;
  }
  .btn-next {
    text-align: left;
  }
}
.ui-pager {
  @include list-unstyled;
  display: none;
  font-weight: 500;

  @include media-breakpoint-up('sm') {
    display: inline-block;
  }

  &-item {
    display: inline-block;

    a {
      display: block;
      padding: 0 .375rem;
      min-width: 1.5rem;
      text-align: center;
      border-radius: 9999px;
      background-color: transparent;
      color: color("black", light);
      transition: $transition;
    }
    a:hover {
      background-color: color("gray", lightest);
    }
    &.is-active {
      a {
        background-color: color(primary);
        color: color("white");
      }
    }
  }
}
.ui-pager--mobile {
  display: inline-block;

  @include media-breakpoint-up('sm') {
    display: none;
  }
}

.ui-pagination-section {
  padding-top: $spacer-xl;

  @include media-breakpoint-up('md') {
    display: flex;

    &::before {
      content: "";
    }
    &::before,
    > * {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      flex-basis: 33.33%;
      white-space: nowrap;
    }
    > *:last-child {
      padding-left: $spacer-md;
      justify-content: flex-start;

      > * {
        margin-left: auto;
      }
    }
  }

  .ui-pagination-limit {
    text-align: center;

    @include media-breakpoint-down('sm') {
      margin-top: $spacer-md;
    }
  }
}
