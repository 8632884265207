
/* --------------------------------------------------------------------------
   Card
   -------------------------------------------------------------------------- */


.ui-card,
.ui-card__block {
  @include button-reset;
  cursor: auto;
}

.ui-card__block,
.ui-card.ui-card__block {
  &.ui-card--xs,
  .ui-card--xs &:not(.ui-card) {
    padding: $card-padding-xs-y $card-padding-xs-x;
  }
  &,
  &.ui-card--md,
  .ui-card--md &:not(.ui-card) {
    padding: $card-padding-y $card-padding-x;
  }
  &.ui-card--sm,
  .ui-card--sm &:not(.ui-card) {
    padding: $card-padding-sm-y $card-padding-sm-x;
  }
  &.ui-card--lg,
  .ui-card--lg &:not(.ui-card) {
    padding: $card-padding-lg-y $card-padding-lg-x;
  }
  &.ui-card--xl,
  .ui-card--xl &:not(.ui-card) {
    padding: $card-padding-lg-y $card-padding-lg-x;

    @include media-breakpoint-up('md') {
      padding: $card-padding-xl-y $card-padding-xl-x;
    }
  }
}

.ui-card {
  display: block;
  margin-bottom: $spacer-4;
  background-color: color("white");
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;

  &:last-child {
    margin-bottom: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.ui-card__header,
.ui-card__footer {
  @extend .ui-card__block;
  background-color: color("white", darker);
  background-color: rgba(color("gray"), .1);
  border-top: $card-border-width solid $card-border-color;
}

.ui-card--interactive {
  border: none;
  background-color: color("white");
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.15);
}
.ui-card--muted {
  background-color: color("gray", lightest);
}
.ui-card--gray {
  background-color: color("white", darker);
  border-color: color("gray", lightest);
}
.ui-card--plain {
  border: none;
}

button.ui-card {
  width: 100%;
}
a.ui-card,
button.ui-card,
label.ui-card,
.ui-card[role="button"] {
  color: inherit;
  cursor: pointer;
  transition: $transition;

  @include hover {
    background-color: color("white", darker);
  }

  &.ui-card--interactive {
    @include hover {
      background-color: color("white");
      box-shadow: 0 2px 3px 0 rgba(0,0,0,.3);
    }
  }
  &.ui-card--muted {
    @include hover {
      background-color: color("gray", lighter);
    }
    &:active {
      // background-color: color("gray", light);
      // text-decoration: none;
    }
  }
  &.ui-card--gray {
    @include hover {
      background-color: color("gray", lightest);
      border-color: color("gray", lightest);
    }
    &:focus {
      text-decoration: none;
    }
    &:active {
      // background-color: color("gray", light);
      // text-decoration: none;
    }
  }
}

.ui-card--overset {
  margin-left: -$card-padding-x;
  margin-right: -$card-padding-x;

  &.ui-card--xs {
    margin-left: -$card-padding-xs-x;
    margin-right: -$card-padding-xs-x;
  }
  &.ui-card--sm {
    margin-left: -$card-padding-sm-x;
    margin-right: -$card-padding-sm-x;
  }
  &.ui-card--lg {
    margin-left: -$card-padding-lg-x;
    margin-right: -$card-padding-lg-x;
  }
  &.ui-card--xl {
    margin-left: -$card-padding-lg-x;
    margin-right: -$card-padding-lg-x;

    @include media-breakpoint-up('md') {
      margin-left: -$card-padding-xl-x;
      margin-right: -$card-padding-xl-x;
    }
  }
}


.ui-card {
  > .row > [class*=col-md-] + [class*=col-md-] {
    @include media-breakpoint-down('sm') {
      margin-top: 1rem;
    }
  }
}
.ui-card__block {
  .row [class*=col-] .ui-form-group {
    margin-bottom: 0;
  }
  > .ui-form-group:last-child,
  .row [class*=col-] .ui-form-group {
    margin-bottom: $spacer-4;
  }
}


.blfy-cards-components {
  > *:not(:last-child) > .ui-card {
    margin-bottom: $spacer-4;
  }
}
