
/* --------------------------------------------------------------------------
   Table
   -------------------------------------------------------------------------- */


.ui-table__list {
  &.ui-table--unlined {
    .ui-table__header,
    .ui-table__list-item-group,
    .ui-table__list-item {
      border-bottom: 0;
    }
  }
  &.ui-table--striped {
    .ui-table__list-item-group:not(.ui-table__list-item) {
      border-bottom: 0;
      margin-bottom: 0;
    }
    .ui-table__list-item:not(.ui-table__list-item-group) {
      border-bottom: 0;
      margin-bottom: 0;

      &:nth-child(2n) {
        background-color: rgba(black, .05);
      }
    }
    .ui-table__header ~ .ui-table__list-item:not(.ui-table__list-item-group) {
      &:nth-child(2n) {
        background-color: transparent;
      }
      &:nth-child(2n+1) {
        background-color: rgba(black, .05);
      }
    }
  }
}

.ui-table__header,
.ui-table__list-item {
  width: 100%;
  padding: .5rem 0;
  border-bottom: $border-width solid rgba(black, .12);

  @include media-breakpoint-up('md') {
    padding: 0;
  }

  .ui-table--justified & {
    margin-left: -$table-padding-x;
    margin-right: -$table-padding-x;
  }
}
.ui-table__list-item-group,
.ui-table__list-item {
  &:last-child {
    border-bottom-width: 0;
  }
}
.ui-table__list {
  *:not(:last-of-type) > .ui-table__list-item-group,
  *:not(:last-of-type) > .ui-table__list-item,
  *:last-of-type > .ui-table__list-item-group:not(:last-child),
  *:last-of-type > .ui-table__list-item:not(:last-child) {
    // List item border bottom when inside of component
    border-bottom-width: $border-width;
  }
}

.ui-table__header-col,
.ui-table__list-item-col {
  padding: ($table-padding-y - .5rem) $table-padding-x;

  @include media-breakpoint-up('md') {
    padding: $table-padding-y $table-padding-x;
  }

  .ui-table--condensed & {
    padding-top: 0;
    padding-bottom: 0;

    @include media-breakpoint-up('md') {
      padding-top: $table-padding-sm-y;
      padding-bottom: $table-padding-sm-y;
    }
  }

  .visible-stacked {
    @include media-breakpoint-up('md') {
      display: none;
    }
  }
}
.ui-table__header-col {
  @include media-breakpoint-up('md') {
    padding-top: ($table-padding-y - .25rem);
    padding-bottom: ($table-padding-y - .25rem);
  }

  .ui-table--condensed & {
    padding-top: 0;
    padding-bottom: 0;

    @include media-breakpoint-up('md') {
      padding-top: ($table-padding-sm-y - .25rem);
      padding-bottom: ($table-padding-sm-y - .25rem);
    }
  }

  .visible-stacked {
    @include media-breakpoint-up('md') {
      display: none;
    }
  }
}

.ui-table__header .ui-table__header--inner,
.ui-table__list-item .ui-table__list-item--inner {
  padding: 0;

  @include media-breakpoint-up('md') {
    > .ui-table__header:last-child,
    > .ui-table__list-item:last-child {
      border: 0;
    }
  }
}

.ui-table__header {
  position: relative;
  display: none;
  margin-bottom: -$border-width;

  @include media-breakpoint-up('md') {
    display: flex;
    justify-content: space-between;

    > .container {
      display: flex;
      justify-content: space-between;
    }
  }

  &-selector {
    display: flex;

    @include media-breakpoint-up('md') {
      display: none;
    }
  }
}

.ui-table__header-col {
  flex: 0 1 auto;
  min-width: 0;

  color: color("black", light);
  font-weight: 500;

  .col-sortable {
    cursor: pointer;
    transition: $transition;
    user-select: none;

    &:hover {
      color: color("black");
    }

    &.asc, &.desc {
      .ui-ico--angle-down {
        display: inline-block;
      }
    }
    .ui-ico--angle-down {
      display: none;
      color: color("gray");

      svg {
        transform: rotate(-180deg);
        transition: transform #{$transition-speed} #{$transition-ease};
      }
    }
    &.asc, &.desc {
      .ui-ico--angle-down {
        color: color("black", light);
      }
    }
    &.asc {
      .ui-ico--angle-down {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
    &.desc {
      .ui-ico--angle-down {
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }

  .col-value {
    small {
      text-transform: none;
    }
  }
}

.ui-table__list-item-group {
  border-bottom: $border-width solid rgba(black, .12);

  .ui-table__list-item {
    border-bottom: 0;
  }

  @include media-breakpoint-down('sm') {
    border-bottom-width: $border-width-thicker;

    .ui-table__list-item {
      border-bottom: $border-width solid rgba(black, .12);

      &:last-child {
        border: 0;
      }
    }
  }
}
.ui-table__list-item-inner {
  height: 20px;
}

.ui-table__list-item {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: left #{$transition-speed} #{$transition-ease};

  > .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ui-table__header-col {
    align-items: center;
  }

  + .ui-table__list-item--sub {
    margin-top: -($table-padding-y - .5rem);

    @include media-breakpoint-up('md') {
      margin-top: - $table-padding-y;
    }
  }

  .ui-table--hover &:hover,
  .ui-table--hover &.is-focused {
    background-image: linear-gradient(90deg, rgba(color("gray"), 0), rgba(color("gray"), .08) 20%, rgba(color("gray"), .08) 80%, rgba(color("gray"), 0) 100%);
  }

  &.is-selected {
    background-color: rgba(color(primary, light), .35);

    &:hover {
      background-image: none;
    }
  }
  &.is-focused {
    .ui-table__list-item-actions {
      z-index: 4;
    }
  }

  .ui-table__list-item-actions {
    position: absolute;
    top: .5rem;
    right: 0;
    z-index: 3;
    order: 99;

    @include media-breakpoint-up('md') {
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: padding-left #{$transition-speed} #{$transition-ease}, opacity #{$transition-speed} #{$transition-ease};
    }
    > * {
      vertical-align: middle;
    }
  }

  @include media-breakpoint-up('md') {
    flex-wrap: nowrap;
  }
}
.ui-table__list-item-group:hover .ui-table__list-item .ui-table__list-item-actions,
.ui-table__list-item:hover .ui-table__list-item-actions,
.ui-table__list-item.is-focused .ui-table__list-item-actions {
  opacity: 1;
}

.ui-table__list-item-col {
  display: flex;
  flex: 0 1 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-width: 0;

  @include media-breakpoint-up('md') {
    flex-basis: auto;
  }
  @include media-breakpoint-down('sm') {
    &.is-empty {
      display: none;
    }
  }
}
a.ui-table__list-item,
a.ui-table__list-item-col {
  color: color("black", light);

  &:hover {
    .ui-ico {
      transform: none;
    }
  }
  &:focus {
    text-decoration: none;

    .col-value,
    ~ a.ui-table__list-item-col .col-value {
      text-decoration: underline;

      * {
        text-decoration: underline;
      }
    }
  }
  &:hover:focus {
    .col-value,
     ~ a.ui-table__list-item-col .col-value {
      text-decoration: none;

      * {
        text-decoration: none;
      }
    }
  }
}

.ui-table__select {
  display: flex;
  position: absolute;
  height: 100%;
  right: 100%;
  top: 0;
  opacity: 0;
  transition: opacity .1s #{$transition-ease}, transform .2s #{$transition-ease};

  @include media-breakpoint-down('sm') {
    transform: translateX(-1rem);
  }

  .ui-checkbox {
    margin-bottom: 0;
    font-size: 0;
  }
  .ui-checkbox__indicator {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0;

    &::after {
      content: "";
      position: absolute;
      width: .625rem;
      height: .625rem;
      margin-top: -.625rem * .5;
      margin-left: -.625rem * .5;
    }
  }
}
.ui-table__header:hover,
.ui-table__header.is-selected,
.ui-table__list-item:hover,
.ui-table__list-item.is-selected,
.ui-table__list.selection-mode {
  .ui-table__select {
    opacity: 1;
  }
}
.selection-mode {
  @include media-breakpoint-down('sm') {
    .ui-table__list-item {
      left: 1.25rem;
    }
    .ui-table__select {
      transform: translateX(.25rem);
    }
  }
}

.ui-table--justify {
  margin-left: -1rem;
  margin-right: -1rem;

  &-condensed {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }


  @each $breakpoint in map-keys($grid-breakpoints) {
    &-#{$breakpoint} {
      @include media-breakpoint-down($breakpoint) {
        margin-left: -$table-padding-x;
        margin-right: -$table-padding-x;
      }
    }
  }
}


.col-title {
  flex: 0 0 auto;

  @include media-breakpoint-down('sm') {
    padding-right: .5rem;
  }
  @include media-breakpoint-up('md') {
    display: none;
  }
}
.col-value {
  width: 100%;
  flex: 0 0 auto;

  @include media-breakpoint-down('sm') {
    .col-title:not(.hidden) + & {
      width: auto;
    }
  }

  @include media-breakpoint-up('md') {
    @include text-ellipsis;
  }

  > .text-info,
  > .text-success,
  > .text-attention,
  > .text-danger {
    font-weight: 500;
  }
}





.ui-table {
  margin-bottom: 1rem;
  background-color: transparent;
  color: color("black", light);

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6,
  p {
    margin-bottom: 0;
  }
}

table.ui-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  th {
    color: color("black", light);
    font-weight: 500;
  }

  th, td {
    vertical-align: middle;
    border-bottom: $table-border-width solid $table-border-color;
  }

  td {
    padding: .75rem 1rem;
  }

  thead {
    th {
      white-space: nowrap;
      overflow: hidden;
      padding: .5rem 1rem;
    }
  }
  tbody {
    tr {
      &.is-selected {
        background: rgba(color(primary, light), .35) !important;
      }
    }
    &:last-child tr:last-child td {
      border-bottom: none;
    }
  }

  &.ui-table--condensed {
    th {
      padding: .25em .75rem;
    }
    td {
      padding: .5rem .75rem;
    }
  }

  &.ui-table--auto {
    width: auto;
  }

  &.ui-table--striped {
    tbody tr {
      &:nth-child(2n) {
        background-color: rgba(color("gray"), .1);
      }
      th, td {
        border-bottom: none;
      }
    }
  }

  &.ui-table--edge-to-edge {
    tr > *:first-child {
      padding-left: 0;
    }
    tr > *:last-child {
      padding-right: 0;
    }
  }

  &.ui-table--bordered {
    th, td {
      border: $table-border-width solid $table-border-color;
    }
  }

  &.ui-table--boxed {
    border: $table-border-width solid $table-border-color;

    th {
      background-color: color("gray", lightest);
    }
  }

  &.ui-table--hover {
    tbody {
      tr {
        &:hover {
          background-image: linear-gradient(-90deg, rgba(color("white", darker), 0) 0%, color("white", darker) 50%, rgba(color("white", darker), 0) 100%);
          //background-attachment: fixed;
        }
      }
    }
  }

  td.is-hidden, th.is-hidden {
    > * {
      visibility: hidden;
    }
  }

  tr input[type="checkbox"] {
    margin: 0;
  }

}

.ui-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}


.ui-table__no-results {
  padding: 1rem $table-padding-x;
  background-color: color("white", darker);
  border: $border-width solid color("gray", lightest);
  text-align: center;

  @include media-breakpoint-up('md') {
    padding: 1.5rem $table-padding-x;
  }
}



/* General styles */

.ui-table__list-item-col.col--id .col-value {
  font-weight: 500;
  color: color("black");
}
a.ui-table__list-item-col.col--id .col-value,
.ui-table__list-item-col.col--id .col-value a {
  font-weight: 500;
  color: color(info);
}

.ui-table__list-item-col.col--name {
  @include media-breakpoint-down('sm') {
    padding-right: 2rem;
  }
}


