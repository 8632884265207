
// ====================================
// Header
// ====================================

.page-header {
  position: relative;
  z-index: $z-header;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: white;
  box-shadow: 0 1px 0 0 rgba(color("gray", light), .2), 0 1px 2px 0 rgba(black, .1);

  // iPhone X landscape safe area
  @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
    padding-left: unquote("max(1.25rem, env(safe-area-inset-left))");
    padding-right: unquote("max(1.25rem, env(safe-area-inset-right))");
  }

  @include media-breakpoint-up('lg') {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    // iPhone X landscape safe area
    @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
      padding-left: unquote("max(1.5rem, env(safe-area-inset-left))");
      padding-right: unquote("max(1.5rem, env(safe-area-inset-right))");
    }
  }

  .ui-button + .ui-button {
    margin-left: $spacer-2;

    @include media-breakpoint-up('lg') {
      margin-left: $spacer-3;
    }
  }

  .page-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding: .75rem 0;

    @include media-breakpoint-up('md') {
      margin-left: -1rem;
      margin-right: -1rem;
    }
    @include media-breakpoint-up('lg') {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .header-item {
      position: relative;
      display: flex;
      align-items: center;
      flex: 0 1 auto;
      padding: .25rem .75rem;

      @include media-breakpoint-up('md') {
        padding-left: 1rem;
        padding-right: 1rem;

        + .header-item::before {
          content: "";
          position: absolute;
          width: $border-width-thicker;
          top: 0;
          bottom: 0;
          left: -$border-width-thicker/2;
          background-color: color("gray", lightest);
          border-radius: 99px;
        }
      }
      @include media-breakpoint-up('lg') {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      &-left,
      &-right {
        position: relative;
      }
      &-adjust,
      &-spacer {
        flex-grow: 1;

        + .header-item {
          &::before {
            display: none;
          }
          @include media-breakpoint-up('md') {
            padding-left: 0;
          }
        }
      }
      &-spacer {
        padding: 0;

        &::before {
          display: none;
        }
      }
    }
    .header-item-inner {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
    }

    .sidebar-toogler {
      @include media-breakpoint-up('lg') {
        display: none;

        &:first-child + .header-item::before {
          display: none;
        }
      }
    }
    .header-brand {
      font-size: 1.75rem;
      line-height: 2rem;
      font-weight: 700;
      white-space: nowrap;
      padding-left: .25rem;

      &::before {
        display: none;
      }

      + .header-nav::before {
        display: none;
      }

      a {
        display: inline-block;
        color: color("black");
        opacity: .99;
        transition: opacity .25s;

        &:hover {
          opacity: .6;
        }
      }
    }
    .header-search {
      position: relative;
      padding: 0 .75rem 0 1rem;

      > .ui-form-group {
        width: 100%;
        margin: 0;
      }

      .ui-ico--ij-search {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -28px *.5;
        color: color(primary, light);
        transition: transform .25s #{$transition-ease}, color .2s #{$transition-ease}, opacity .15s #{$transition-ease};
        opacity: 1;
      }
      .ui-input {
        label {
          display: block;
          cursor: text;
          margin: 0;
        }
      }
      .ui-input--inner {
        padding: .8125rem .75rem;
        padding-left: rem(28px) + .5rem;
        background-color: transparent !important;
        border: none;
        font-weight: 500;
        transition: $transition, padding-left #{$transition-speed} #{$transition-ease};

        @include placeholder {
          color: color(primary, $variation: light);
        }
        &:focus {
          padding-left: .75rem;

          @include placeholder {
            color: color(primary, $variation: light);
          }

          ~ wi-icon .ui-ico--ij-search {
            transform: translateX(-100%);
            cursor: auto;
            color: color(primary, $variation: light);
            opacity: 0;
          }
        }
      }
    }
    .header-nav {
      .nav-item {
        display: inline-block;
        vertical-align: middle;

        > a {
          color: color("black", light);
        }

        + .nav-item {
          margin-left: 1rem;

          @include media-breakpoint-up('md') {
            margin-left: 1rem;
          }
          @include media-breakpoint-up('lg') {
            margin-left: 1.25rem;
          }
        }
      }
      .header-nav-search {
        @include media-breakpoint-up('md') {
          display: none;
        }
      }
      .header-nav-notifications {
        position: relative;
        margin-top: -1px;
        margin-bottom: -1px;

        &.has-nofitication a {
          color: color(attention);

          &:hover {
            color: shade(color(attention), 55%);

            .count {
              margin-top: -2px;
            }
          }
        }
        .count {
          position: absolute;
          z-index: 1;
          top: -.5rem;
          right: -.5rem;
          margin-top: 0;
          transition: margin-top .1s #{$transition-ease};
        }
      }
      .header-nav-user {
        .ui-dropdown-toggle {
          font-weight: 500;
          color: color("black", light);

          &:hover {
            color: color("black");
          }
        }
        .ui-dropdown-menu {
          h5 {
            margin-top: .25rem;
          }
        }
        #logout {
          margin-top: .75rem;
        }
        #logoutButton::before {
          background-color: color("gray", lighter);
        }
      }
    }

    .header-menu {
      @include media-breakpoint-down('sm') {
        display: none;
      }
    }

  }

  .header-alert-container {
    display: block;
    padding: .5rem;
    padding-bottom: 0;
    text-align: center;
    font-size: $font-size-lg;

    @include media-breakpoint-down('md') {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }

    .ui-alert {
      margin: 0;

      .email-verification__adress {
        @include media-breakpoint-down('xs') {
          word-break: break-word;
        }
      }

      .ui-action-button {
        margin-left: $spacer-2;
        color: color("black");

        @include media-breakpoint-down('sm') {
          display: block;
          margin: auto;
          margin-top: $spacer-2;
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    .header-search,
    .header-nav-user,
    .header-nav-settings {
      display: none !important;
    }
  }

}

.create-invoice-nav {
  z-index: $z-sub-header;

  h1 {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 700;
  }
  .sidebar-toggle {
    display: inline-block;
    margin-right: 1.5rem;

    @include media-breakpoint-down('md') {
      display: none;
    }
  }
  .status {
    margin-left: 1rem;

    @include media-breakpoint-up('lg') {
      margin-right: 2rem;
    }

    .status-indicator {
      display: inline-block;
      width: .75rem;
      height: .75rem;
      border-radius: 50%;
      background-color: color(attention);
    }
    .status-label {
      display: inline-block;
      color: color("black", light);
    }
  }
  .ui-toggle-switch {
    margin-right: 1rem;

    @include media-breakpoint-up('lg') {
      margin-right: 2.5rem;
    }
  }
  .header-item::before {
    display: none;
  }
}




// Hamburger
// ==================================================

$hamburger-layer-width                     : 14px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 2px;
$hamburger-layer-color                     : color("gray", light);
$hamburger-hover-layer-color               : color("gray");
$hamburger-transition-speed                : .25s;


.hamburger {
  @include button-reset;
  width: 2.125rem;
  height: 2.125rem;
  display: block;
  cursor: pointer;
  border: $border-width-thicker solid color("gray", light);
  border-radius: 9999px;
  transition: border-color #{$transition-speed} #{$transition-ease};

  &:hover {
    border-color: color("gray");
  }
}
.hamburger-box {
  display: block;
  position: relative;
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  margin: auto;

  perspective: $hamburger-layer-width * 2;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;
  transition: transform #{$hamburger-transition-speed} cubic-bezier(.645, .045, .355, 1),
              background-color #{$transition-speed} #{$transition-ease};

  &,
  &::before,
  &::after {
    position: absolute;
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: 99px;

    .hamburger:hover & {
      background-color: $hamburger-hover-layer-color;
    }
  }
  &::before,
  &::after {
    content: "";
    display: block;
    transition: transform #{$hamburger-transition-speed} cubic-bezier(.645, .045, .355, 1),
                background-color #{$transition-speed} #{$transition-ease};
  }
  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}
.hamburger.is-active {
  .hamburger-inner {
    background-color: transparent;
    transform: rotateY(180deg);

    &::before {
      transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
    }

    &::after {
      transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
    }
  }
}
