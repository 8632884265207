
// ====================================
// Footer
// ====================================

.page-footer {
  @include clearfix;
  position: relative;
  z-index: $z-footer;
  padding: .25rem 1.25rem;
  background: color("white", darker);
  box-shadow: 0 (- $border-width) 0 0 rgba(black, .1);
  font-size: $font-size-sm;
  line-height: 2rem;
  color: color("gray");

  // iPhone X safe areas
  @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
    padding-left: unquote("max(1.25rem, env(safe-area-inset-left))");
    padding-right: unquote("max(1.25rem, env(safe-area-inset-right))");
    padding-bottom: unquote("max(.25rem, env(safe-area-inset-bottom))");
  }

  @include media-breakpoint-up('md') {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    // iPhone X safe areas
    @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
      padding-left: unquote("max(1.5rem, env(safe-area-inset-left))");
      padding-right: unquote("max(1.5rem, env(safe-area-inset-right))");
    }
  }

  .page-footer-inner {
    margin-left: -$nav-item-padding-x;
    margin-right: -$nav-item-padding-x;
  }

  .footer-copy {
    padding: $nav-item-padding-y $nav-item-padding-x;
    color: color("gray", light);

    @include media-breakpoint-up('md') {
      float: left;
    }

    .intrum-brand {
      float: left;
      margin-right: 1.25rem;

      svg {
        height: 20px;
        width: 90px;
        top: -1px;

        * {
          fill: color("gray", light);
        }
        .no-change {
          fill: transparent;
        }
      }
    }
    .copy-text {
      white-space: nowrap;
    }
  }

  .footer-nav {
    color: color("gray");

    @include media-breakpoint-up('md') {
      float: right;
    }

    .ui-nav {
      margin-bottom: 0;
    }
    .ui-nav-item {
      color: color("gray");

      &:hover {
        color: color("black", light);
      }
    }
  }
}
