
/* --------------------------------------------------------------------------
   Timeline
   -------------------------------------------------------------------------- */


.ui-timeline {
  position: relative;

  .ui-message {
    width: 95%;
    max-width: 300px;
    margin-bottom: 1rem;
    color: color("black");

    .message-error {
      color: $body-color;
      font-weight: 500;
    }
  }
  .ui-message--sent {
    margin-left: auto;
  }
  .ui-message--alert {
    width: 100%;
    max-width: none;
    margin-bottom: 1.5rem;
  }
}

.ui-message-form {
  .ui-input--inner {
    min-height: 6rem;
    padding-bottom: 3.25rem;
  }
  .send-button {
    text-align: right;
    padding-top: $input-padding-md-x/2;

    @include media-breakpoint-down('xs') {
      display: flex;
      justify-content: flex-end;
      left: $input-padding-md-x/2;
      right: $input-padding-md-x/2;
      bottom: $input-padding-md-x/2;

      .ui-button {
        width: auto;
      }
    }
  }
  .invoice-timeline-terms {
    margin-left: $input-padding-md-x/2;
    margin-right: .5rem;
  }
  .message-form-closed-alert {
    padding-bottom: .25rem;
    border-bottom: $border-width solid color("gray", lightest);
  }
}

.ui-message-bubble {
  position: relative;
  padding: .75rem 1rem;
  background-color: color("gray", lightest);
  border-radius: $border-radius-md;

  h4 {
    margin-bottom: $spacer-1;
    font-size: $font-size-h4;
  }
  p {
    margin-bottom: 0;
    word-break: break-word;
  }

  &:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 15px;
    top: 0;
    right: 100%;
  }

  .ui-message--received & {
    border-top-left-radius: 0;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M8,15C8,7.63,4.53,3.66.32,1.19A.64.64,0,0,1,.64,0H8Z' fill='#{encode-hex(color("gray", lightest))}' fill-rule='evenodd'/%3E%3C/svg%3E");
      top: 0;
      right: 100%;
    }
  }
  .ui-message--sent & {
    border-top-right-radius: 0;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M0,15C0,7.63,3.47,3.66,7.68,1.19A.64.64,0,0,0,7.36,0H0Z' fill='#{encode-hex(color("gray", lightest))}' fill-rule='evenodd'/%3E%3C/svg%3E");
      left: 100%;
      right: auto;
    }
  }
}
.ui-message {
    &--fullypaid {
    .ui-message-bubble {
      @extend .ui-alert--fullypaid;
    }
    &.ui-message--received .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M8,15C8,7.63,4.53,3.66.32,1.19A.64.64,0,0,1,.64,0H8Z' fill='#{encode-hex(color(fullypaid, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    &.ui-message--sent .ui-message-bubble {
      @extend .ui-alert--blue;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M0,15C0,7.63,3.47,3.66,7.68,1.19A.64.64,0,0,0,7.36,0H0Z' fill='#{encode-hex(color("blue", light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
  }
  &--written,
  &--success {
    .ui-message-bubble {
      @extend .ui-alert--success;
    }
    &.ui-message--received .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M8,15C8,7.63,4.53,3.66.32,1.19A.64.64,0,0,1,.64,0H8Z' fill='#{encode-hex(color(success, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    &.ui-message--sent .ui-message-bubble {
      @extend .ui-alert--blue;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M0,15C0,7.63,3.47,3.66,7.68,1.19A.64.64,0,0,0,7.36,0H0Z' fill='#{encode-hex(color("blue", light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
  }
  &--attention {
    .ui-message-bubble {
      @extend .ui-alert--attention;
    }
    &.ui-message--received .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M8,15C8,7.63,4.53,3.66.32,1.19A.64.64,0,0,1,.64,0H8Z' fill='#{encode-hex(color(attention, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    &.ui-message--sent .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M0,15C0,7.63,3.47,3.66,7.68,1.19A.64.64,0,0,0,7.36,0H0Z' fill='#{encode-hex(color(attention, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
  }
  &--danger {
    .ui-message-bubble {
      @extend .ui-alert--danger;
    }
    &.ui-message--received .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M8,15C8,7.63,4.53,3.66.32,1.19A.64.64,0,0,1,.64,0H8Z' fill='#{encode-hex(color(danger, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    &.ui-message--sent .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M0,15C0,7.63,3.47,3.66,7.68,1.19A.64.64,0,0,0,7.36,0H0Z' fill='#{encode-hex(color(danger, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
  }
  &--info {
    .ui-message-bubble {
      @extend .ui-alert--info;
    }
    &.ui-message--received .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M8,15C8,7.63,4.53,3.66.32,1.19A.64.64,0,0,1,.64,0H8Z' fill='#{encode-hex(color(info, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    &.ui-message--sent .ui-message-bubble::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='15' viewBox='0 0 8 15'%3E%3Cpath d='M0,15C0,7.63,3.47,3.66,7.68,1.19A.64.64,0,0,0,7.36,0H0Z' fill='#{encode-hex(color(info, light))}' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
  }
}

.ui-message-meta {
  margin-top: .25rem;
  font-size: $font-size-sm;
  color: $text-muted;
}

.ui-message--alert {
  .ui-message-bubble {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: $font-size-sm;

    &:before {
      display: none;
    }
  }
}

.ui-message-attachment {
  margin-top: .5rem;
  margin-bottom: -.25rem;
  padding-top: .5rem;
  border-top: $border-width solid rgba(black, .1);
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  > .ui-action-button {
    width: 100%;
  }
  > a,
  > .ui-action-button {
    @include clearfix;
    color: inherit;

    &:hover, &:focus {
      .attachment-name {
        opacity: 1;
      }
    }
    &:focus {
      text-decoration: none;

      .attachment-name {
        text-decoration: underline;
      }
    }
  }
  .attachment-name {
    float: left;
    opacity: .75;
  }
  .attachment-show {
    float: right;
  }
}

#ui-message-upcoming {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: $border-width solid rgba(black, .1);

  .ui-message:last-child {
    margin-bottom: 0;
  }
}

.message-date {
  position: relative;

  .message-spinner {
    position: absolute;
    margin-left: .25rem;
  }
}
