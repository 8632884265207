
/* --------------------------------------------------------------------------
   Form
   -------------------------------------------------------------------------- */


.ui-form-section,
.ui-form-section-separator {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  @include media-breakpoint-up('md') {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  &:first-child {
    margin-top: 0;
  }
}
.ui-form-subsection {
  margin-bottom: 1rem;

  @include media-breakpoint-up('md') {
    margin-bottom: 1.25rem;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    [class*="col-#{$breakpoint}"] > & {
      @include media-breakpoint-up($breakpoint) {
        margin-bottom: 0;
      }
    }
  }
}
.ui-form-section__title {
  small {
    font-family: $font-family-base;
    color: $text-muted;
  }
  &.is-expanded {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ui-add-form-field {
      order: 2;
      color: color("black");

      .ui-ico--ball {
        margin-right: 0;
        background-color: color(attention);
      }
      @include hover {
        .ui-ico--ball {
          //background-color: color(attention);
        }
      }
    }
  }
}

.ui-form-block {
  margin-bottom: 1.5rem;
}

.ui-form-group {
  position: relative;
  margin-bottom: 1rem;

  > .ui-form-group,
  &:last-child {
    margin-bottom: 0;
  }
  &:only-child {
    margin-bottom: 1rem;
  }

  &.is-required {
    &:before {
      content: "*";
      position: relative;
      float: right;
      top: .375rem;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: color(attention);
    }
  }

  &--xs {
    margin-bottom: $spacer-2;
  }
  &--sm {
    margin-bottom: .75rem;
  }
  &--lg {
    .ui-input-label {
      font-size: $font-size-base;
    }
  }
}

.remove-form-block {
  position: absolute;
  z-index: 1;
  top: .5rem;
  right: 0;
  transform: translateX(50%);
}

.ui-input {
  position: relative;

  &--inline {
    display: inline-block;
  }
}

.ui-input-label {
  margin-bottom: .25rem;
  font-size: $font-size-sm;
  font-weight: 500;
  transition: color #{$transition-speed} #{$transition-ease};

  small {
    color: color("gray");
  }
  &--hidden {
    display: none !important;

    html.no-placeholder & {
        display: inline-block !important;
    }
  }
}

.ui-input-block {
  position: relative;
  background-color: color("white");
  border: $border-width solid color("gray", lightest);
  color: color("black");

  &--row {
    display: flex;
  }

  .ui-input-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: .25rem;
    padding-bottom: .25rem;
    background-color: color("white", darker);
    border-bottom: $border-width solid color("gray", lightest);
    font-weight: 500;

    &.ui-input--xs {
      padding-left: $input-padding-xs-x*1.5;
      padding-right: $input-padding-xs-x*1.5;
    }
    &.ui-input--sm {
      padding-left: $input-padding-sm-x*1.5;
      padding-right: $input-padding-sm-x*1.5;
    }
  }

  > .ui-input:last-child,
  div:not(.ui-input-block--row) > .ui-input:last-child {
    margin-bottom: -$border-width;
  }
  .ui-input {
    display: flex;
    flex-grow: 1;
    width: auto;
    margin-left: $input-padding-md-x;
    margin-right: $input-padding-md-x;

    .input-label {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      border-bottom: $border-width solid $input-border-color;
      transition: $transition;

      .ui-label {
        opacity: 0;
        transform: translateX(-20%);
        transition: $transition;
      }
    }
    .ui-input--inner {
      padding-left: $input-padding-md-x/2;
      padding-right: $input-padding-md-x/2;

      &:hover ~ .input-label {
        border-color: $input-border-color-hover;
      }
      &:focus {
        ~ .input-label {
          pointer-events: auto;
          position: static;
          border-color: $input-border-color-focus;

          .ui-label {
            opacity: 1;
            transform: translateX(0);
          }
        }
        ~ .ui-input-group__suffix {
          display: none;
        }
      }
    }
  }
  .has-success .ui-input--inner {
    &, &:hover, &:focus {
       ~ .input-label {
        border-color: color(success);
      }
    }
  }
  .has-warning .ui-input--inner {
    &, &:hover, &:focus {
       ~ .input-label {
        border-color: color(attention);
      }
    }
  }
  .has-danger .ui-input--inner {
    &, &:hover, &:focus {
       ~ .input-label {
        border-color: color(danger);
      }
    }
  }
  .ui-input--inner,
  .ui-input-group__suffix {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .ui-input--xs {
    margin-left: $input-padding-xs-x;
    margin-right: $input-padding-xs-x;

    .ui-input--inner,
    .ui-input-group__suffix {
      padding-left: $input-padding-xs-x/2;
      padding-right: $input-padding-xs-x/2;
    }
  }
  .ui-input--sm {
    margin-left: $input-padding-sm-x;
    margin-right: $input-padding-sm-x;

    .ui-input--inner,
    .ui-input-group__suffix {
      padding-left: $input-padding-sm-x/2;
      padding-right: $input-padding-sm-x/2;
    }
  }

  + .ui-form-action {
    margin-top: .75rem;
  }
}

.ui-input-group {
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;

  &__addon,
  &__suffix,
  &__button {
    display: flex;
    align-items: center;
    transition: $transition;
  }
  &__addon {
    background-color: color("white", darker);
    color: color("gray");
    border: $border-width solid color("gray", lightest);
    border-right-width: 0;
    padding: 0 .75rem;

    &:last-child {
      border-right-width: $border-width;
    }
  }
  &__suffix {
    background-color: color("white");
    color: color("gray");
    border: $border-width solid $input-border-color;
    border-left-width: 0;
    border-right-width: 0;
    padding: 0 .75rem;

    .ui-input--inner + & {
      margin-left: -1px;
    }

    &:last-child {
      border-right-width: $border-width;
    }
  }
  &__button {
    .ui-button {
      border-width: $border-width;
      box-shadow: none;
      font-family: $font-family-base;
      font-weight: 500;

      &.ui-button--default {
        //border-color: color("gray", lightest);
      }
    }
    * + & > .ui-button {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__text,
  > .ui-input--inner {
    vertical-align: middle;
    display: table-cell;

    &:focus {
      + .ui-input-group__button .ui-button {
        border-left-color: $input-border-color-focus;
      }
    }

    + .ui-input-group__addon {
      border-left-width: 0;
    }
  }
}

.ui-input--muted {
  .ui-input--inner {
    background-color: color("white", darker);
    border-color: color("white", darker);

    @include placeholder {
      color: color("gray", light);
    }

    &:hover,
    &:focus {
      background-color: color("white", darker);
      border-color: color("white", darker);
    }
  }
}

.ui-input--inner {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-image: none;
  outline: none;
  vertical-align: middle;
  line-height: 1.125;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  text-align: left;

  box-sizing: border-box;
  margin: 0;
  padding: $input-padding-md-y $input-padding-md-x;

  background-color: color("white");
  border-radius: $border-radius-md;
  border: $border-width solid $input-border-color;
  color: $input-color;
  transition: $transition;

  font-family: $font-family-base;
  font-size: 1rem;
  font-weight: 400;

  &:hover {
    &,
    ~ .ui-input-group__addon,
    ~ .ui-input-group__suffix,
    ~ .ui-input-group__button {
      border-color: $input-border-color-hover;
    }
  }
  &:focus {
    &,
    ~ .ui-input-group__addon,
    ~ .ui-input-group__suffix,
    ~ .ui-input-group__button {
      border-color: $input-border-color-focus;
    }
  }

  @include placeholder {
    color: color("gray", light);
    transition: color #{$transition-speed} #{$transition-ease};
  }

  &:disabled,
  &.is-disabled {
    background-color: color("white", darker);
    color: color("gray", light);
    cursor: not-allowed;

    &:hover, &:focus {
      border-color: color("gray", lightest);
    }
  }

  &.is-inactive {
    color: color("gray", light);

    @include placeholder {
      color: color("gray", light);
    }
  }

}
button.ui-input--inner {
  cursor: pointer;
}
.ui-input:not(.ui-select) .ui-input--inner:read-only {
  background-color: color("white", darker);
  color: color("black", light);

  &:hover, &:focus {
    border-color: color("gray", lightest);
  }
}


@keyframes input-note-show {
  from {
    opacity: 0;
    max-height: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    max-height: 200px;
    transform: translateY(0);
  }
}
.ui-input-note {
  font-size: $font-size-sm;
  font-weight: 500;
  color: color("black", light);

  * + & {
    margin-top: .25rem;
  }
  button, a {
    font-weight: 500;
    color: inherit;

    &:hover, &:focus {
      color: color("black");
    }
  }

  .ui-input-validation {
    animation: input-note-show #{$transition-speed};
  }
}

.ui-input__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  pointer-events: none;
  color: color("gray");

  .ui-input--xs & {
    line-height: 2rem;
  }
  .ui-input--sm & {
    line-height: 2.25rem;
  }
  .ui-input--lg & {
    line-height: 3rem;
  }
}
.ui-input__icon--right {
  right: 0;
  left: auto;
}
.ui-input--left-icon {
  &.ui-input--xs .ui-input--inner {
    padding-left: 1.5rem + $input-padding-xs-x/2;
  }
  &.ui-input--sm .ui-input--inner {
    padding-left: 1.75rem + $input-padding-sm-x/2;
  }
  &.ui-input .ui-input--inner {
    padding-left: 2rem + $input-padding-md-x/2;
  }
  &.ui-input--lg .ui-input--inner {
    padding-left: 2.5rem + $input-padding-lg-x/2;
  }
}
.ui-input--right-icon {
  &.ui-input--xs .ui-input--inner {
    padding-right: 1.5rem + $input-padding-xs-x/2;
  }
  &.ui-input--sm .ui-input--inner {
    padding-right: 1.75rem + $input-padding-sm-x/2;
  }
  &.ui-input .ui-input--inner {
    padding-right: 2rem + $input-padding-md-x/2;
  }
  &.ui-input--lg .ui-input--inner {
    padding-right: 2.5rem + $input-padding-lg-x/2;
  }
}

.ui-input--xs {
  .ui-input--inner {
    padding: $input-padding-xs-y $input-padding-xs-x;
  }
  textarea.ui-input--inner {
    padding: $input-padding-xs-x;
  }
  .ui-input__icon {
    width: 1.75rem;
  }
}
.ui-input--sm {
  .ui-input--inner {
    padding: $input-padding-sm-y $input-padding-sm-x;
  }
  textarea.ui-input--inner {
    padding: $input-padding-sm-x;
  }
  .ui-input__icon {
    width: 2rem;
  }
}
.ui-input--lg {
  .ui-input--inner {
    padding: $input-padding-lg-y $input-padding-lg-x;
  }
  textarea.ui-input--inner {
    padding: $input-padding-lg-x;
  }
  .ui-input__icon {
    width: 3rem;
  }
}
.ui-input--xl {
  .ui-input--inner {
    padding: $input-padding-xl-y $input-padding-xl-x;
    padding: 1rem 1.25rem;
    font-size: 1.25rem;
  }
}

.ui-input--left-icon .ui-input--inner[type="search"] {
  transition: $transition, padding-left #{$transition-speed} #{$transition-ease};

  &:focus {
    padding-left: $input-padding-md-x;

    ~ .ui-input__icon {
      transform: translateX(-50%);
      opacity: 0;
    }
  }
  ~ .ui-input__icon {
    transition: transform #{$transition-speed} #{$transition-ease}, opacity #{$transition-speed} #{$transition-ease};
  }
}


.ui-input--editable {
  .ui-input--inner {
    border-right-width: 0;

    @include placeholder {
      font-weight: 500;
    }

    ~ .ui-input-group__button .ui-button {
      background-color: rgba(tint(color(primary), 50%), .35);
      border-color: tint(color(primary), 50%);
      color: shade(color(primary), 25%);
      border-left-width: 0;
      margin-left: 0;
    }

    &.has-input,
    &:focus {
      border-right-width: $input-border-width;

      ~ .ui-input-group__button {
        display: none;
      }
    }

    &:not(:focus):not(.has-input) {
      background-color: rgba(tint(color(primary), 50%), .35);
      border-color: tint(color(primary), 50%);

      @include placeholder {
        color: color(primary);
      }

      ~ .ui-input-group__button {
        //display: block;
      }
    }

    .has-danger &:not(:focus) {
      background-color: rgba(tint(color(danger), 50%), .35);
      border-color: color(danger);
      color: color(danger);

      ~ .ui-input-group__button .ui-button {
        background-color: rgba(tint(color(danger), 50%), .35);
        border-color: color(danger);
        color: shade(color(danger), 25%);
      }
    }
  }
}


//
// Textarea
//

textarea.ui-input--inner {
  height: 6rem;
  min-height: 2rem;
  max-height: 20rem;
  overflow-y: auto !important;
  padding: $input-padding-md-x;
  white-space: normal;
}


//
// Select
//

.ui-select {
  display: block;
  width: auto;

  &::disabled {

  }

  .ui-input--inner {
    cursor: pointer;
    // background-color: color("white");
    // color: color("black");
    -moz-appearance: none;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
      transition: none;
    }
  }

  &:focus {
    ~ .ui-input__icon .ui-ico--angle-down {
      transform: rotate(-180deg);
    }
  }
  .ui-ico--angle-down {
    transition: transform #{$transition-speed} #{$transition-ease};
  }

  &--inline {
    white-space: nowrap;

    @include placeholder {
      color: inherit;
    }

    .ui-input--inner {
      display: inline;
      padding: 0;
      background: transparent;
      border: none;
      color: inherit;
    }
  }
}



//
// Radio and checkbox
//

.ui-radio-group,
.ui-checkbox-group {
  margin-bottom: -.5rem;
}

.ui-radio,
.ui-checkbox {
  margin-right: 1rem;
  margin-bottom: $spacer-2;
  cursor: pointer;

  .ui-input > & {
    margin-bottom: .25rem;
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  &:last-child {
    margin-right: 0;
  }

  > input {
    @include input-hidden;

    &:focus {
      ~ .ui-radio__indicator,
      ~ .ui-checkbox__indicator {
        border-color: color(primary);
      }
    }
    &:disabled {
      cursor: not-allowed;

      ~ .ui-radio__indicator,
      ~ .ui-checkbox__indicator {
        background-color: color("gray", lightest);
        border-color: color("gray", lighter);
      }
      ~ .ui-radio__label,
      ~ .ui-checkbox__label {
        color: color("gray", light);
      }
    }
  }
}
.ui-radio input:disabled:checked ~ .ui-radio__indicator,
.ui-checkbox input:disabled:checked ~ .ui-checkbox__indicator,
.ui-checkbox input:disabled:indeterminate ~ .ui-checkbox__indicator,
.ui-checkbox input.is-indeterminate:disabled ~ .ui-checkbox__indicator {
  background-color: color("gray", lightest);
  border-color: color("gray", lighter);

  &::before {
    background-color: color("gray", light);
  }
}

.ui-radio__indicator,
.ui-checkbox__indicator {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .25rem;
  vertical-align: middle;
  border: $border-width solid color("gray", lighter);
  transition: border #{$transition-speed} #{$transition-ease};

  &::before {
    content: "";
    position: absolute;
    width: .75rem;
    height: .75rem;
    background-color: color(primary);
    top: 50%;
    left: 50%;
    margin-top: -.75rem * .5;
    margin-left: -.75rem * .5;
    transform: scale(0);
    transition: all .1s #{$transition-ease};
  }
}
.ui-radio input:checked ~ .ui-radio__indicator,
.ui-checkbox input:checked ~ .ui-checkbox__indicator,
.ui-checkbox input:indeterminate ~ .ui-checkbox__indicator,
.ui-checkbox input.is-indeterminate ~ .ui-checkbox__indicator {
  border-color: color(primary);

  &::before {
    transform: scale(1);
  }
  .ui-ico--check svg {
    transform: scale(1);
    opacity: 1;
  }
}
.ui-checkbox {
  input {
    &.is-indeterminate,
    &:indeterminate {
      ~ .ui-checkbox__indicator {
        &::before {
          width: .625rem;
          height: .25rem;
          margin-top: -.25rem * .5;
          margin-left: -.625rem * .5;
        }
      }
    }
  }
}
.ui-radio__indicator {
  border-radius: 9999px;

  &::before {
    border-radius: 9999px;
  }
}
.ui-radio__label,
.ui-checkbox__label {
  display: inline-block;
  vertical-align: middle;
}


@function radio-card-padding-left($base-padding) {
  @return $base-padding + 1.5rem + .75rem;
}
.ui-checkbox-card,
.ui-radio-card {
  display: block;
  position: relative;
  margin: 0;

  .ui-card__block {
    cursor: pointer;
  }

  @include media-breakpoint-down('sm') {
    .ui-card__block {
      &.ui-card--xs {
        padding-left: radio-card-padding-left($card-padding-xs-x);
      }
      &, &.ui-card--md {
        padding-left: radio-card-padding-left($card-padding-x);
      }
      &.ui-card--sm {
        padding-left: radio-card-padding-left($card-padding-sm-x);
      }
      &.ui-card--lg {
        padding-left: radio-card-padding-left($card-padding-lg-x);
      }
    }
  }

  > .ui-radio__indicator,
  > .ui-checkbox__indicator {
    position: absolute;
    left: $card-padding-x;
    top: $card-padding-y;
    margin: $border-width-thicker 0 0 0;

    @include media-breakpoint-up('md') {
      width: 2rem;
      height: 2rem;
      left: auto;
      right: 100%;
      top: 50%;
      margin: -1rem 1.25rem 0 0;
      border-width: $border-width-thicker;
    }

    &::before {
      background-color: color("white");
    }
    .ui-ico--check svg {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 12px;
      margin-left: -8px;
      margin-top: -6px;
      transform: scale(0);
      opacity: 0;
      color: color("white");
      transition: all .2s #{$transition-ease};
    }
  }
  > .ui-checkbox__indicator::before {
    display: none;
  }

  .ui-card {
    @extend .ui-card--gray;
    border-width: $border-width;
    color: color("black");
    transition: $transition;

    .option-title {
      margin-bottom: 0;
    }
    .toggle-content {
      display: none;
      margin-top: .5rem;
    }
  }
  > input:checked {
    ~ .ui-radio__indicator,
    ~ .ui-checkbox__indicator {
      border-width: .75rem;
      border-color: color(success, dark);

      @include media-breakpoint-down('sm') {
        .ui-ico--check svg {
          transform: scale(.75);
        }
      }
      @include media-breakpoint-up('md') {
        border-width: 1rem;
      }
    }
    ~ .ui-card {
      border-width: $border-width-thicker;
      background-color: rgba(color(success), .05);
      border-color: color(success);
      color: color(success, dark);
      box-shadow: 0 0 8px 0 rgba(color(success), .2);

      &.ui-card__block {
        $checked-card-border-diff: $border-width-thicker - $border-width;

        &.ui-card--xs {
          padding: calc(#{$card-padding-xs-y} - #{$checked-card-border-diff}) calc(#{$card-padding-xs-x} - #{$checked-card-border-diff});

          @include media-breakpoint-down('sm') {
            padding-left: calc(#{radio-card-padding-left($card-padding-xs-x)} - #{$checked-card-border-diff});
          }
        }
        &, &.ui-card--md {
          padding: calc(#{$card-padding-y} - #{$checked-card-border-diff}) calc(#{$card-padding-x} - #{$checked-card-border-diff});

          @include media-breakpoint-down('sm') {
            padding-left: calc(#{radio-card-padding-left($card-padding-x)} - #{$checked-card-border-diff});
          }
        }
        &.ui-card--sm {
          padding: calc(#{$card-padding-sm-y} - #{$checked-card-border-diff}) calc(#{$card-padding-sm-x} - #{$checked-card-border-diff});

          @include media-breakpoint-down('sm') {
            padding-left: calc(#{radio-card-padding-left($card-padding-sm-x)} - #{$checked-card-border-diff});
          }
        }
        &.ui-card--lg {
          padding: calc(#{$card-padding-lg-y} - #{$checked-card-border-diff}) calc(#{$card-padding-lg-x} - #{$checked-card-border-diff});

          @include media-breakpoint-down('sm') {
            padding-left: calc(#{radio-card-padding-left($card-padding-lg-x)} - #{$checked-card-border-diff});
          }
        }
      }

      .toggle-content {
        display: block;
      }
      .ui-radio__indicator,
      .ui-checkbox__indicator {
        border-color: color(success, dark);

        &::before {
          background-color: color(success, dark);
        }
      }
    }
  }

  &.ui-checkbox-card--purple {
    > input:checked {
      ~ .ui-radio__indicator,
      ~ .ui-checkbox__indicator {
        border-color: color("purple", dark);
      }
      ~ .ui-card {
        background-color: rgba(color("purple"), .05);
        border-color: color("purple");
        color: color("purple", dark);
        box-shadow: 0 0 8px 0 rgba(color("purple"), .2);

        .ui-radio__indicator,
        .ui-checkbox__indicator {
          border-color: color("purple", dark);

          &::before {
            background-color: color("purple", dark);
          }
        }
      }
    }
  }
  &.ui-checkbox-card--blue {
    > input:checked {
      ~ .ui-radio__indicator,
      ~ .ui-checkbox__indicator {
        border-color: color("blue", dark);
      }
      ~ .ui-card {
        background-color: rgba(color("blue"), .05);
        border-color: color("blue");
        color: color("blue", dark);
        box-shadow: 0 0 8px 0 rgba(color("blue"), .2);

        .ui-radio__indicator,
        .ui-checkbox__indicator {
          border-color: color("blue", dark);

          &::before {
            background-color: color("blue", dark);
          }
        }
      }
    }
  }
  &.ui-checkbox-card--petrol {
    > input {
      &:checked,
      &:focus {
        ~ .ui-radio__indicator,
        ~ .ui-checkbox__indicator {
          border-color: color("petrol", dark);
        }
      }
      &:checked {
        ~ .ui-card {
          background-color: rgba(color("petrol"), .05);
          border-color: color("petrol");
          color: color("petrol", dark);
          box-shadow: 0 0 8px 0 rgba(color("petrol"), .2);

          .ui-radio__indicator,
          .ui-checkbox__indicator {
            border-color: color("petrol", dark);

            &::before {
              background-color: color("petrol", dark);
            }
          }
        }
      }
    }
  }
}


//
// Datepicker
//

.ui-datepicker--inline {
  @include button-reset;
}



//
// Add form field
//

.ui-add-form-field {
  @include button-reset;
  font-size: 1rem;
  font-weight: 400;
  transition: $transition;
  color: color(info);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  @include hover {
    color: shade(color(info), 25%);

    .ui-ico--ball {
      background-color: shade(color(info), 25%);
    }
  }
  &:active {
    color: shade(color(info), 50%);

    .ui-ico--ball {
      background-color: shade(color(info), 50%);
    }
  }

  .ui-ico--ball {
    margin-right: .5rem !important;
    background-color: color(info);
    color: color("white");
  }
  icon:last-child .ui-ico--ball {
    margin-right: 0;
  }

  &:disabled,
  &.is-disabled {
    color: color("gray");

    .ui-ico--ball {
      background-color: color("gray");
    }
  }
  &.is-added {
    @include hover {
      .ui-ico--ball {
        transform: scale(1);
      }
    }
  }

  &.ui-add-form-field--lg {
    font-size: 1.25rem;
  }
  &.ui-add-form-field--sm {
    .ui-ico--ball {
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;

      &.ui-ico--add svg {
        width: 10px;
        height: 10px;
      }
      &.ui-ico--remove svg {
        width: 10px;
        height: 2px;
      }
    }
  }
}

//
// Lower action buttons
//

.ui-form-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
  margin-bottom: -.5rem;

  &--sm {
    margin-top: 1rem;
  }

  > .ui-form-action__row,
  > .ui-alert {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    > *:last-child {
      text-align: right;
    }
  }
  > *:only-child {
    margin-left: auto;
  }
  .ui-button {
    margin-bottom: $spacer-2;
  }

  @include media-breakpoint-down('xs') {
    * + .ui-button {
      margin-top: 1rem;
    }
  }
}



//
// Toggle Switch
//

.ui-toggle-switch {
  display: inline-block;
  vertical-align: middle;

  > span {
    display: inline-block;
    vertical-align: middle;
    color: color("black", light);
    transition: opacity #{$transition-speed} #{$transition-ease};
  }
  > .label-off {
    margin-right: .25rem;
  }
  > .label-on {
    margin-left: .25rem;
    opacity: .5;
  }

}
.switch {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: $toggle-width;
  height: $toggle-diameter + $toggle-gutter*2;
  margin: 0;
  border-radius: $toggle-diameter;
  background: rgba(color("gray", lighter), .25);
  overflow: hidden;
  cursor: pointer;

  &,
  .knob,
  .switch-label {
    transition: all .2s ease-in-out;
  }
  .knob {
    display: inline-block;
    position: absolute;
    top: $toggle-gutter;
    width: $toggle-diameter;
    height: $toggle-diameter;
    border-radius: 100%;
    background-color: color("gray", light);
    //box-shadow: 0 1px 2px 0 rgba(black, .1), 0 2px 3px 0 rgba(black, .1);
    z-index: 10;
  }
  .switch-label {
    position: absolute;
    width: $toggle-width - $toggle-diameter - $toggle-gutter*2;
    top: 0;
    bottom: 0;
    padding: $toggle-gutter 0;
    opacity: 0;
    overflow: hidden;
    font-size: $font-size-sm;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    line-height: $toggle-diameter + $toggle-gutter;
    color: color("white");
  }
}

// Off
.switch {
  .knob {
    left: $toggle-gutter;
  }
  .label-on {
    left: -$toggle-width;
    z-index: 6;
  }
  .label-off {
    right: $toggle-gutter;
    opacity: 1;
  }
}

.checkbox-switch {
  position: absolute;
  z-index: -1;
  opacity: 0;

  // On
  &:checked {
    ~ .label-off {
      opacity: .5;
    }
    ~ .label-on {
      opacity: 1;
    }
    ~ .switch {
      background: rgba(color(success), .25);

      .knob {
        left: $toggle-width - $toggle-diameter - $toggle-gutter;
        background-color: color(success);
      }
      .label-on {
        left: $toggle-gutter;
        opacity: 1;
      }
      .switch-label.label-off {
        right: -$toggle-width;
        z-index: 5;
      }
    }
  }

  // Disabled
  &:disabled ~ .switch {
    opacity: .65;
    filter: alpha(opacity=65);
    cursor: not-allowed;
  }
}

.ui-toggle-switch--equality {
  .switch {
    background: rgba(color(success), .25);

    .knob {
      background-color: color(success);
    }
  }
}



// Validation


.has-success {
  .ui-input-label,
  .ui-input-validation,
  .ui-radio__label,
  .ui-checkbox__label {
    color: color(success);
  }
  .ui-input--inner:not(:disabled) {
    border-color: color(success);
  }
}
.has-warning {
  .ui-input-label,
  .ui-input-validation,
  .ui-radio__label,
  .ui-checkbox__label {
    color: color(attention);
  }
  .ui-input--inner:not(:disabled) {
    border-color: color(attention);
  }
}
.has-danger {
  .ui-input-label,
  .ui-input-validation,
  .ui-radio__label,
  .ui-checkbox__label {
    color: color(danger);
  }
  .ui-input--inner:not(:disabled) {
    border-color: color(danger);
  }
}
.ui-input .ui-input--inner:focus {
  &:valid {
  }
  &:invalid {
  }
}


// File upload

.file-upload-container {
  &.has-image {
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;

    .file-upload {
      visibility: hidden;
    }
  }
}
.file-upload {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  margin: 0;
  padding: 0;
  color: color("gray", light);
  text-align: center;
  cursor: pointer;
  transition: color .1s #{$transition-ease}, border .1s #{$transition-ease}, opacity #{$transition-speed};

  border: 16px solid transparent;
  border-image-source: url("/assets/img/border-dashed--gray-light@2x.png");
  border-image-slice: 16;
  border-image-width: .5;
  border-image-outset: 0;
  border-image-repeat: round;

  &:hover {
    color: color("black", light);
    border-image-source: url("/assets/img/border-dashed--black-light@2x.png");
  }
  label {
    @include center-both(absolute);
    display: block;
    width: 100%;
    margin: 0;
    cursor: inherit;
  }
  input {
    @include input-hidden;
  }
}
.file-upload-buttons {
  margin-top: .25rem;
  text-align: right;
}
.invoice-logo {
  max-width: 360px;

  .file-upload {
    height: 0;
    padding-top: calc(25.78125% - 32px); // logo height / logo width * 100= 16,5/64*100
  }
}


// Edit blocks

.ui-ico--edit {
  transition: $transition;

  button & {
    color: color("gray");
  }
  button:hover & {
    color: color("black");
  }
}
