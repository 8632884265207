.page {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;

  @media print {
    display: block;
    height: auto;
    overflow: auto;
  }
}

.page-main {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  position: relative;
  overflow: auto;

  .page & {
    flex-shrink: 0;
  }

  @media print {
    display: block;
  }
}

.is-boxed-mode,
.is-fullscreen-mode {
  .app-layout {
    height: 100vh;
    overflow: hidden;
  }
  .page-main {
    flex-shrink: 1;
  }
  .page-content {
    width: 100%;
    overflow-y: auto;
  }
}

.is-fullscreen-mode {
  .page-content {
    @include media-breakpoint-up('md') {
      padding: 0;
    }
  }

  .ng-trigger-sidebarAnimation {
    position: absolute;
    z-index: $z-sidebar-floating;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.page-content-container {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
}

.page-content {
  display: block;
  padding: $page-content-padding-y-sm $page-content-padding-x-sm;

  &:not(.no-footer-spacer) {
    padding-bottom: 3rem;
  }

  // iPhone X safe areas
  @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
    padding-left: unquote("max(#{$page-content-padding-x-sm}, env(safe-area-inset-left))");
    padding-right: unquote("max(#{$page-content-padding-x-sm}, env(safe-area-inset-right))");
  }

  @include media-breakpoint-up('md') {
    padding: $page-content-padding-y $page-content-padding-x;

    &:not(.no-footer-spacer) {
      padding-bottom: 4rem;
    }

    // iPhone X safe areas
    @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
      padding-left: unquote("max(#{$page-content-padding-x}, env(safe-area-inset-left))");
      padding-right: unquote("max(#{$page-content-padding-x}, env(safe-area-inset-right))");
    }
  }

  @include media-breakpoint-up('xl') {
    padding: $page-content-padding-y-lg $page-content-padding-x-lg;

    &:not(.no-footer-spacer) {
      padding-bottom: 5rem;
    }
  }
}

/* Page Content */

.page-title {
}

.page-section {
  * + & {
    margin-top: $spacer-xl;

    &.page-section--lg {
      margin-top: $spacer-xxxl;
    }
  }
}

.content-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: $spacer-lg;

  @include media-breakpoint-up('md') {
    margin-bottom: $spacer-xl;
  }

  .page-title {
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down('sm') {
  .content-header {
    .page-title {
      width: auto;
      margin-right: .5rem;
    }

    .ui-nav-container {
      height: 2.125rem;
      margin-bottom: 1rem;
      margin-left: auto;
    }

    .ui-nav--tabs.ui-nav--responsive {
      align-items: flex-end;

      .ui-nav-item {
        .ui-ico--angle-down {
          display: inline-block;
          visibility: hidden;
        }

        &.is-active {
          .ui-ico--angle-down {
            visibility: visible;
          }
        }
      }
    }
  }

  .page-actions {
    width: 100%;
  }
}

.page-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .ui-form-group {
    @include media-breakpoint-up('md') {
      margin-bottom: 0;
    }
  }

  .bulk-actions {
    z-index: 4; // Fix when in mobile viewport

    @include media-breakpoint-down('sm') {
      order: 2;
      align-self: flex-start;
      margin-top: .5rem;
      margin-left: auto;
      margin-bottom: -3rem;

      &.is-hidden {
        display: none;
      }
    }
    @include media-breakpoint-up('md') {
      padding-right: $grid-gutter-width;
    }
  }

  .search {
    flex-grow: 1;
    overflow: hidden;
    width: 100%;

    @include media-breakpoint-down('sm') {
      .ui-form-group {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-up('md') {
      width: auto;
      margin-bottom: 0;

      .ui-form-group {
        max-width: 460px;
      }
    }
  }

  .create {
    @include media-breakpoint-down('sm') {
      .ui-button {
        @include action-button;
        @include action-button--primary;

        .ui-ico {
          margin: 0;
        }
      }
    }

    @include media-breakpoint-up('md') {
      padding-left: $grid-gutter-width;
    }

    flex: 1;
  }
}

.ui-button.create {
  @include media-breakpoint-up('md') {
    display: none;
  }
}

.content-page {
  max-width: 750px;
}
