.app {
  height: 100vh;

  @media print {
    height: auto;
    overflow: auto;
  }
}

.app-layout {
  display: flex;
  min-height: 100vh;

  @media print {
    display: block;
    min-height: initial;
  }
}
