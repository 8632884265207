
.progress-steps {
  display: flex;
  position: relative;
  align-items: flex-start;
  text-align: center;
  border-top: $progress-width solid color("gray", lightest);

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: relative;
    flex-grow: 1;
    height: $progress-width;
    top: -$progress-width;
  }
  &::before {
    background-color: color(primary);
  }

  &--inline {
    border-color: transparent;

    &::before,
    &::after {
      display: none;
    }
  }
}
.progress-step {
  @include button-reset();

  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding: 0 .25rem;
  top: -$progress-width;
  text-align: center;
  color: color("gray");

  .progress-steps--inline &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    left: -50%;
    top: 0;
    height: $progress-width;
    @include border-right-radius(99px);
    background-color: color("gray", lightest);
  }
  .progress-steps--inline &:first-child::before {
    width: 50%;
    left: 0;
  }

  &:first-child {
    .step-progress {
      left: 0;
    }
  }
  .step-progress {
    position: absolute;
    width: 0;
    left: -50%;
    top: 0;
    height: $progress-width;
    @include border-right-radius(99px);
    background-color: color(primary);
    transition: width .5s ease-out;
  }
  .step-number {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 1.5rem;
    height: 1.5rem;
    top: -$progress-width;
    border-radius: 99px;
    background-color: color("gray", lightest);
    color: color("gray");
    font-size: $font-size-sm;
    font-weight: 500;
    line-height: 1.5rem;
    transition: background-color .35s ease-in-out, color .35s ease-in-out, transform .15s ease-in-out;

    .ui-ico--check {
      display: none;
      margin: 0 !important;
      color: color("white");
      transform: rotate(-90deg);
    }
  }
  .step-label {
    margin-top: .375rem;
    transition: $transition;

    .progress-steps--inline & {
      white-space: nowrap;
    }
  }

  @include hover {
    .step-number {
      transform: scale(1.15);
    }
  }
  &:hover {
    color: color("black");

    .step-number {
      background-color: color("gray", lighter);
      color: color("black", light);
    }
  }
  &.is-done,
  &.is-active {
    color: color("black");

    &:first-child {
      .step-progress {
        width: 50%;
      }
    }
    .step-progress {
      width: 100%;
    }
    .step-number {
      background-color: color(primary);
      color: color("white");
    }
  }
  &.is-done {
    .step-number {
      transform: rotate(90deg);

      span {
        display: none;
      }
      .ui-ico--check {
        display: inline-block;
      }
    }
  }
}
