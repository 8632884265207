
/* --------------------------------------------------------------------------
   Background
   -------------------------------------------------------------------------- */


@include color-component("bg", "background", true);

.bg-primary {
  background-color: color(primary);
  color: color("white");

  .text-muted {
    color: rgba(color("white"), .75);
  }

  &-dark {
    background-color: color(primary, dark);
    color: color("white");
  }
  &-light {
    background-color: color(primary, light);
    color: color("white");
  }
  &-lighter {
    background-color: color(primary, light);
  }
}
.bg-secondary {
  background-color: color("purple");
  color: color("white");

  &-dark {
    background-color: color("purple", dark);
    color: color("white");
  }
  &-light {
    background-color: color("purple");
    color: color("white");
  }
  &-lighter {
    background-color: color("purple", light);
  }
}
.bg-tertiary {
  background-color: color("blue");
  color: color("white");

  &-dark {
    background-color: color("blue", dark);
    color: color("white");
  }
  &-light {
    background-color: color("blue");
    color: color("white");
  }
  &-lighter {
    background-color: color("blue", light);
  }
}

.bg-success {
  background-color: color(success);

  &-dark {
    background-color: color(success, dark);
  }
  &-light {
    background-color: color(success, light);
  }
}
.bg-attention {
  background-color: color(attention);

  &-dark {
    background-color: color(attention, dark);
  }
  &-light {
    background-color: color(attention, light);
  }
}
.bg-danger {
  background-color: color(danger);

  &-dark {
    background-color: color(danger, dark);
  }
  &-light {
    background-color: color(danger);
  }
}
.bg-info {
  background-color: color(info);

  &-dark {
    background-color: color(info, dark);
  }
  &-light {
    background-color: color(info, light);
  }
}
