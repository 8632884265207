
/* --------------------------------------------------------------------------
   Variables
   -------------------------------------------------------------------------- */


/* Grid
   -------------------------------------------------------------------------- */

$enable-flex: true;

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:        12;
$grid-gutter-width:   30px !default;


/* Body
   -------------------------------------------------------------------------- */


$body-color: color("black");
$body-bg: color("white", dark) !default;


/* Framework
   -------------------------------------------------------------------------- */

$page-content-padding-x-sm: 1.25rem;
$page-content-padding-y-sm: 1.25rem;
$page-content-padding-x: 2rem;
$page-content-padding-y: 1.5rem;
$page-content-padding-x-lg: 2.5rem;
$page-content-padding-y-lg: 2rem;

// Spacing
$spacer-xs: .5rem;
$spacer-sm: .75rem;
$spacer-md: 1rem;
$spacer-lg: 1.5rem;
$spacer-xl: 2rem;
$spacer-xxl: 2.5rem;
$spacer-xxxl: 3.75rem;

$spacer-1: .25rem;
$spacer-2: .5rem;
$spacer-3: .75rem;
$spacer-4: 1rem;
$spacer-5: 1.5rem;
$spacer-6: 2rem;
$spacer-7: 2.5rem;
$spacer-8: 4rem;
$spacer-9: 5rem;
$spacer-10: 7.5rem;


$spacer-y: 1rem;

$border-width: 1px;
$border-width-thicker: 2px;

$border-radius-sm: 0;
$border-radius-md: 0;
$border-radius-lg: 0;

$border-color: rgba(black, .1);


/* Typography
   -------------------------------------------------------------------------- */

$font-path: "../../fonts" !default;
$font-family-base: "Intrum Sans", Arial, "Helvetica Neue", sans-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px;

$font-size-xs:   .75rem;
$font-size-sm:   .875rem;
$font-size-base: 1rem;
$font-size-lg:   1.125rem;
$font-size-xl:   1.25rem;

$line-height-sm: 1.25;
$line-height-base: 1.375;
$line-height-lg: 1.5;

$font-size-h1: 2rem;
$font-size-h2: 1.5rem;
$font-size-h3: 1.25rem;
$font-size-h4: 1rem;
$font-size-h5: .875rem;

$heading-margin-bottom: 1rem;
$heading-font-weight:   700;
$heading-line-height:   1.1;
$heading-color:         inherit;

$dt-font-weight: bold;

$text-muted: color("gray") !default;

$z-sidebar:                   20;
$z-footer:                    30;
$z-completer:                 52;
$z-backdrop:                  60;
$z-sidebar-sm:                70;
$z-sidebar-floating:          80;
$z-invoice-settings-sidebar:  90;
$z-invoice-settings-toggle:   91;
$z-invoice-overlay:           92;
$z-invoice-settings-slideout: 93;
$z-sub-header:                99;
$z-header:                    100;
$z-backdrop-top:              200;
$z-modal:                     250;
$z-notification:              300;


/* Link
   -------------------------------------------------------------------------- */

$link-color:            color(primary);
$link-disabled-color:   color("gray");
$link-decoration:       none;
$link-hover-color:      shade($link-color, 25%);
$link-hover-decoration: none;


/* Button
   -------------------------------------------------------------------------- */

$button-padding-xs-x: .5rem;
$button-padding-xs-y: .375rem;

$button-padding-sm-x: .75rem;
$button-padding-sm-y: .5rem;

$button-padding-md-x: 1rem;
$button-padding-md-y: .625rem;

$button-padding-lg-x: 1.25rem;
$button-padding-lg-y: .875rem;

$button-padding-xl-x: 1.5rem;
$button-padding-xl-y: 1rem;

$button-border-width: $border-width;

$button-border-radius-xs: 0;
$button-border-radius-sm: 0;
$button-border-radius-md: 0;
$button-border-radius-lg: 0;
$button-border-radius-xl: 0;

$button-font-size-xs: $font-size-sm;
$button-font-size-sm: $font-size-base;
$button-font-size-md: $font-size-base;
$button-font-size-lg: $font-size-base;
$button-font-size-xl: $font-size-lg;


/* Label
   -------------------------------------------------------------------------- */

$label-padding-md-x: .375em;
$label-padding-md-y: .125em;

$label-padding-pill-sm-x: .25em;
$label-padding-pill-md-x: .5em;

$label-border-width: $border-width;

$label-border-radius: $border-radius-sm;


/* Nav
   -------------------------------------------------------------------------- */

$nav-item-padding-x: .5rem;
$nav-item-padding-y: .25rem;

$nav-tabs-item-padding-x: .5rem;
$nav-tabs-item-padding-y: .375rem;


/* Form
   -------------------------------------------------------------------------- */

$input-padding-xs-x: .625rem;
$input-padding-xs-y: .375rem;

$input-padding-sm-x: .75rem;
$input-padding-sm-y: .5rem;

$input-padding-md-x: 1rem;
$input-padding-md-y: .625rem;

$input-padding-lg-x: 1.25rem;
$input-padding-lg-y: .875rem;

$input-padding-xl-x: 1rem;
$input-padding-xl-y: 1.25rem;

$input-color: color("black");

$input-border-width: $border-width;
$input-border-color: color("gray", lightest);
$input-border-color-hover: color("gray", lighter);
$input-border-color-focus: color(primary);

$input-border-radius-xs: $border-radius-sm;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-md: $border-radius-sm;
$input-border-radius-lg: $border-radius-md;


$toggle-diameter: 1.25rem;
$toggle-width: 2.25rem;
$toggle-gutter: 0;


/* Alert
   -------------------------------------------------------------------------- */

$alert-padding-md-xs-x: .75rem;
$alert-padding-md-xs-y: .5rem;

$alert-padding-sm-x: 1rem;
$alert-padding-sm-y: .625rem;

$alert-padding-md-x: 1rem;
$alert-padding-md-y: .75rem;

$alert-padding-lg-x: 1.25rem;
$alert-padding-lg-y: 1.25rem;

$alert-border-radius: $border-radius-md;
$alert-border-radius-sm: $border-radius-sm;


/* Card
   -------------------------------------------------------------------------- */

$card-padding-xs-x: .75rem;
$card-padding-xs-y: .5rem;

$card-padding-sm-x: 1rem;
$card-padding-sm-y: .75rem;

$card-padding-x: 1.25rem;
$card-padding-y: 1rem;

$card-padding-lg-x: 1.5rem;
$card-padding-lg-y: 1.25rem;

$card-padding-xl-x: 2rem;
$card-padding-xl-y: 2rem;

$card-background: color("white");
$card-border-radius: $border-radius-sm;
$card-border-radius-sm: $border-radius-sm;
$card-border-radius-lg: $border-radius-md;
$card-border-width: $border-width;
$card-border-color: color("gray", lightest);

/* Table
   -------------------------------------------------------------------------- */

$table-border-width: $border-width;
$table-border-color: color("gray", lightest);

$table-padding-sm-y: .375rem;
$table-padding-x: .5rem;
$table-padding-y: .75rem;


/* Animation
   -------------------------------------------------------------------------- */

$transition-speed: .2s;
$transition-ease: ease-in-out;
$transition:
  background #{$transition-speed} #{$transition-ease},
  border-color #{$transition-speed} #{$transition-ease},
  color #{$transition-speed} #{$transition-ease},
  box-shadow #{$transition-speed} #{$transition-ease},
  opacity #{$transition-speed} #{$transition-ease},
  transform #{$transition-speed} #{$transition-ease};
