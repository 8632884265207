.invoice-status {
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-base;

  .ij-spinner {
    margin-left: 0.125rem;
    margin-right: -0.125rem;
  }
}

.customer-name {
  a {
    color: color("black");
  }
}

.invoice-header {
  color: color("black");
  font-size: $font-size-h2;
  font-weight: 500;
}

.auto-invoice-number {
  font-size: $font-size-sm;
}

/* Invoice card */

.ij-invoice-card {
  &.ui-card {
    border: 0;
  }

  .ui-card__footer,
  .ui-card__block {
    padding-top: 0.5rem !important;
    padding-bottom: 1rem !important;
  }

  .invoice-number {
    margin-bottom: $spacer-2;
  }
  .invoice-date {
    flex-basis: 130px;
  }
  .invoice-amount {
    flex-basis: 120px;
    flex-grow: 1;
  }
  .download-button {
    text-align: right;
    align-self: flex-end;
    flex-grow: 1;
    margin-right: 0 !important;

    @include media-breakpoint-down("xs") {
      width: 100%;
    }
  }
  .invoice-link {
    align-self: flex-end;
    margin-top: 0.75rem;

    a {
      font-weight: 500;
    }
  }
  .detail-block {
    display: inline-block;
    margin-right: $spacer-lg;
  }
  .ui-card__footer {
    padding-top: $card-padding-sm-y;
    padding-bottom: $card-padding-sm-y;
  }
}
