
/* --------------------------------------------------------------------------
   Dropdown
   -------------------------------------------------------------------------- */


.ui-dropdown {
  display: inline-block;
  position: relative;

  &:not(.ui-dropdown--input):not(.ui-dropdown--boxed) .ui-dropdown-toggle {
    @include button-reset;
  }
  .ui-dropdown-toggle {
    font-weight: 500;
    cursor: pointer;
    transition: $transition;

    svg {
      transition: none;
    }
  }
  &.is-expanded {
    .ui-dropdown-menu {
      display: block;
    }
  }
}

@keyframes dropdown-show {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: .25rem;
  }
}
.ui-dropdown-menu {
  display: none;
  overflow: hidden;
  animation: dropdown-show .35s;
  @include list-unstyled;
  position: absolute;
  z-index: 9;
  right: 0;
  top: 100%;
  margin-top: .25rem;
  padding: .25rem 0;
  border-radius: $border-radius-md 0 $border-radius-md $border-radius-md;
  background-color: color("gray", lightest);
  box-shadow: 0 1px 8px 0 rgba(black, .1);

  &.ui-dropdown-menu--left {
    left: 0;
    right: auto;
    border-radius: 0 $border-radius-md $border-radius-md $border-radius-md;
  }
}
.ui-dropdown-item {
  @include button-reset;
  @extend .ui-nav-item;
  cursor: auto;
  position: relative;
  width: 100%;
  display: block;
  padding: .25rem .75rem;
  color: color("black");
  font-weight: 500;
  white-space: nowrap;
  transition: color #{$transition-speed} #{$transition-ease};

  > * {
    z-index: 1;

    + * {
      margin-left: .5rem;
    }
  }

  span {
    white-space: normal;
  }

  .ui-dropdown--condensed & {
    padding: 1px .75rem;

    > * + * {
      margin-left: .25rem;
    }
  }
  &.ui-dropdown-item--justify {
    display: flex;
    justify-content: space-between;
  }

  .ui-ico {
    color: color("black");
  }
  &[class^='text-'],
  &[class*=' text-'] {
    .ui-ico {
      color: inherit !important;
    }
  }

  &:hover, &:focus {
    .ui-ico {
      color: color("black");
    }
  }
  &:focus {
    color: color(primary, dark);
  }
  &.text-muted {
    color: color("gray");
  }
  &.is-active {
    color: color(primary);

    > span {
      border-bottom: $border-width solid color(primary);
    }
  }
  &:disabled,
  &.is-disabled {
    color: color("gray") !important;

    .ui-ico {
      color: color("gray") !important;
    }
    &::before {
      content: none;
    }
  }
}
a.ui-dropdown-item,
button.ui-dropdown-item,
label.ui-dropdown-item {
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: -.25rem;
    bottom: -.25rem;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: transparent;
    transition: color #{$transition-speed} #{$transition-ease}, background-color #{$transition-speed} #{$transition-ease};

    .ui-dropdown--condensed & {
      top: 0;
      bottom: 0;
    }
  }
  &:hover,
  &.is-selected {
    z-index: 1;

    &::before {
      background-color: color("gray", lighter);
    }
    &.text-danger {
      color: color(danger);
    }
  }
  &.is-selected {
    color: color("white");

    &::before {
      background-color: color(primary);
    }
    .ui-ico {
      color: color("white");
    }
  }
  &.text-muted {
    &:hover {
      color: color("black", light);
    }
  }
}

.ui-dropdown-divider {
  width: 100%;
  height: $border-width;
  margin-top: .25rem;
  margin-bottom: .25rem;
  background-color: color("gray", lighter);
}

.ui-dropdown--boxed {
  display: inline-block;

  .ui-dropdown-toggle {
    @include button-reset;
    width: 1.5rem;
    border: $border-width solid color("gray", lighter);
    border-radius: $border-radius-sm;
    display: inline-block;
    text-align: center;
    color: color("gray", light);

    > span {
      display: none;
    }
    .ui-ico {
      margin: 0 !important;
    }

    &:hover {
      border-color: color("gray", light);
    }
    &:focus {
      outline: none;
      border-color: color("black", light);
    }
  }
}

.ui-dropdown--input {
  display: block;

  .ui-dropdown-toggle {
    @extend .ui-input--inner;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
  }
  .ui-ico--angle-down {
    margin-left: .5rem;
  }
}


.ui-dropdown .ui-dropdown-item .ui-ico--support {
  margin-left: $spacer-xs;
  color: color("black");
}
