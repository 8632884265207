
/* --------------------------------------------------------------------------
   Navigation
   -------------------------------------------------------------------------- */


.ui-nav {
  margin-bottom: 1.25rem;
}

.ui-nav-item {
  @include button-reset;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  user-select: none;
  cursor: pointer;
}
.ui-nav-item,
.ui-nav-label {
  position: relative;
  display: inline-block;
  padding: $nav-item-padding-y $nav-item-padding-x;
}
.ui-nav-label {
  color: color("black");
}
.ui-nav-item {
  font-weight: 500;
  color: color("black", light);

  .ui-label {
    margin-left: .25rem;
  }
  .ui-ico {
    color: color("gray");
    transition: color #{$transition-speed} #{$transition-ease};
  }

  &:hover, &:focus {
    color: color("black");
    text-decoration: none;

    .ui-ico {
      color: color("black", light);
    }
  }
  &.is-active {
    color: color(primary);

    .ui-ico {
      color: color(primary);

      svg .inner-stroke {
        color: color(primary);
      }
    }
  }
  &.is-disabled {
    cursor: not-allowed;

    &, &:hover, &:focus,
    .ui-ico {
      color: $text-muted;
    }

    .ui-label {
      background-color: color("gray", light);
    }
  }
  &.ui-button {
    @extend .ui-button--sm;
    border-style: solid;
    font-family: inherit;

    + .ui-nav-item {
      margin-left: .5rem;
    }
  }
  + .ui-nav-item.ui-button {
    margin-left: .5rem;
  }
}
.ui-nav-text {
  cursor: default;

  &, &:hover, &:focus, &.is-active {
    color: color("black", lighter);
  }
}

.ui-nav-divider {
  display: inline-block;
  width: $border-width;
  height: 1.25rem;
  vertical-align: middle;
  background-color: color("gray", lighter);
}


.ui-nav--vertical {
  margin-left: -$nav-tabs-item-padding-x;
  margin-right: -$nav-tabs-item-padding-x;

  .ui-nav-group + .ui-nav-group {
    margin-top: .5rem;
  }

  .ui-nav-item {
    display: block;
    margin-bottom: $spacer-2;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &.ui-nav--compact {
    .ui-nav-item {
      display: block;
      margin-bottom: 0;
    }
  }

  .ui-nav-divider {
    display: block;
    width: 100%;
    height: $border-width;
    margin: .25rem 0;
  }

  .ui-nav-item--justify {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
  }
}

@keyframes nav-item-show {
  from {
    opacity: 0;
    top: -.5rem;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
@keyframes nav-backdrop-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.ui-nav--tabs {
  // Because of nav item margin
  margin-top: -.125rem;

  .ui-nav-item {
    position: relative;
    margin-right: .5rem;
    margin-top: .125rem;
    margin-bottom: .125rem;
    padding: $nav-tabs-item-padding-y $nav-tabs-item-padding-x;
    border-radius: $border-radius-sm;

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      right: 0;
      left: 0;
      height: 100%;
      z-index: -1;

      border-radius: $border-radius-sm;
      background-color: transparent;
      transition:
        top #{$transition-speed} #{$transition-ease},
        background-color #{$transition-speed} #{$transition-ease},
        box-shadow #{$transition-speed} #{$transition-ease};
    }

    &:last-child {
      margin-right: 0;
    }

    &:not(.is-active) {
      .ui-label {
        background-color: color("gray", light);
      }
    }

    &:hover,
    &.is-active {
      color: color("black", light);
      // background-color: white;
      // box-shadow: 0 1px 8px 0 rgba(black, .08);

      &::before {
        top: 0;
        background-color: white;
        box-shadow: 0 1px 8px 0 rgba(black, .08);
      }
    }
    &:active {
      &::before {
        box-shadow: 0 1px 4px 0 rgba(black, .18);
      }
    }
    &:disabled,
    &.is-disabled {
      color: $text-muted;
      // background-color: transparent;;
      // box-shadow: none;

      &::before {
        display: none;
      }

      .ui-label {
        background-color: color("gray", lighter);
      }
    }

    .ui-ico--angle-down {
      display: none;
      color: color("black");
      margin-left: .25rem;
      transition: transform #{$transition-speed} #{$transition-ease};
    }
  }

  &.ui-nav--responsive {
    @include media-breakpoint-down('sm') {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      //box-shadow: 0 0 10px 10px red;
      position: relative;
      z-index: 4;

      &::before {
        content: "";
        display: none;
        position: absolute;
        z-index: -1;
           top: -.25rem;
         right: -.25rem;
        bottom: -.25rem;
          left: -.25rem;
        background: $body-bg;
        filter: blur(1rem);
        opacity: .9;
        animation: nav-backdrop-show .3s;
      }

      &.is-expanded {
        &::before {
          display: block;
        }
        .ui-ico--angle-down {
          transform: rotate(-180deg);
        }
      }

      &:not(.is-expanded) .ui-nav-item:not(.is-active) {
        display: none;
      }

      .ui-nav-item {
        margin-right: 0;
        animation: nav-item-show .2s;

        &.is-active {
          .ui-ico--angle-down {
            display: inline-block;
          }
        }
      }
    }
  }
}

.ui-nav-group {
  .ui-nav-item {
    margin-left: .5rem;
  }
}
.ui-nav-group-title {
  padding: $nav-item-padding-y $nav-item-padding-x;
  font-size: $font-size-lg;
  color: color("black", light);
  font-weight: 500;
}
