
@page {
  size: auto;
  margin: 10mm 10mm 10mm 10mm;
}

@media print {
  * {
    background-color: transparent !important;
  }
  html {
    font-size: 8pt;
  }
  body {
    height: auto !important;
    overflow: auto !important;
    background-color: white;
  }

  // Hide parts
  wi-header-component,
  sidebar-component,
  footer-component,
  .ui-nav-container,
  .page-actions,
  .ui-table__header-selector,
  .ui-table__list-item-actions {
    display: none !important;
  }

  .page-title {
    margin-bottom: 0 !important;
  }

  // Table
  .ui-table__list * {
    color: black !important;
  }
  .ui-table__list-item {
    page-break-inside: avoid;
  }

  // Pagination
  .ui-pagination {
    display: block;

    .btn-prev, .btn-next {
      display: none;
    }
  }
  .ui-pagination-limit {
    display: none;
  }

  // Layout
  .page-main {
    display: block;
  }
  .page-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Show links
  // a[href^="/"]:after {
  //   content: "(http://sme.intrum.com" attr(href) ")";
  // }

}
@page:left{
  @bottom-left {
    content: "Page " counter(page) " of " counter(pages);
  }
}
