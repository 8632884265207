

.is-boxed-mode,
.is-fullscreen-mode {
  #ij-show-case {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
}

#ij-show-case-details,
#ij-show-case-timeline {
  @include media-breakpoint-down('sm') {
    &:not(.is-active-content) {
      display: none !important;
    }
  }
}

#ij-show-case-details {
  overflow: auto;

  @include media-breakpoint-down('sm') {
    &, .page-content {
      width: 100%;
    }
  }
  @include media-breakpoint-up('md') {
    flex-grow: 1;
    height: 100%;

    .show-case-details {
      width: 720px;
      max-width: 100%;
    }
  }
  @include media-breakpoint-up('lg') {
    .page-content {
      padding-right: 80px;
    }
  }

  .invoice-in-dc-alert {
    @include media-breakpoint-down('xs') {
      .title {
        display: inline-block;
        margin-bottom: .25rem;
      }
    }
    @include media-breakpoint-up('sm') {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $spacer-lg;
    }

    .title {
      font-weight: 500;

      strong {
        vertical-align: middle;
      }
    }
  }
}
#ij-show-case-timeline {
  width: 100%;

  @include media-breakpoint-up('md') {
    width: $invoice-timeline-width-md;
    height: 100%;
    background: white;
    border-left: $border-width solid color("gray", lightest);
    flex-shrink: 0;
    transform: width .5s #{$transition-ease};
  }
  @include media-breakpoint-up('xl') {
    width: $invoice-timeline-width-xl;
  }
  @include media-breakpoint-up('xxl') {
    width: $invoice-timeline-width-xxl;
  }

  // Span full width in web invoice for tablet
  #ij-show-case.customer-invoice & {
    @include media-breakpoint-only('md') {
      width: 100%;
      flex-basis: auto;
      border-left: none !important;

      .ui-timeline {
        width: 600px;
        margin: auto;
      }
    }
  }

  .page-content {
    @include media-breakpoint-between('md', 'lg') {
      padding-left: $page-content-padding-y;
      padding-right: $page-content-padding-y;
    }
    @include media-breakpoint-only('xl') {
      padding-left: $page-content-padding-y-lg;
      padding-right: $page-content-padding-y-lg;
    }
  }

  .timeline-header {
    margin-bottom: 1.25rem;
    border-bottom: $border-width solid color("gray", lightest);
  }

  @media print {
    display: none;
  }
}
#toggle-case-timeline {

  right: 1.5rem;
  bottom: 1.5rem;  
  
    // iPhone X safe areas
  @supports(padding: max(0px)) and (-webkit-overflow-scrolling: touch) {
    right: unquote("max(1.5rem, env(safe-area-inset-right))");
    bottom: unquote("max(1.5rem, env(safe-area-inset-bottom))");
  }


  @include media-breakpoint-up('md') {
    display: none;
  }
  #ij-show-case.customer-invoice & {
    @include media-breakpoint-only('md') {
      display: inline-block;
      width: auto;

      > span {
        position: static;
        left: 0;
        z-index: initial;
        opacity: 1;
        padding: .5rem 3rem .5rem 1rem;
        font-weight: 500;
        font-size: $font-size-lg;
      }
      .ui-ico {
        left: auto;
        right: 1.75rem;
        transform: translate(50%, -50%);
      }
    }
  }
}


.case-header {
  margin-bottom: $spacer-md;

  @include media-breakpoint-up('sm') {
    margin-bottom: $spacer-lg;
  }
  @include media-breakpoint-up('md') {
    margin-bottom: $spacer-xl;
  }
  @include media-breakpoint-up('lg') {
    margin-bottom: $spacer-xxl;
  }

  .customer-name {
    margin-bottom: 0;
    color: color("black", light);
  }
  .case-id {
    margin-bottom: .25rem;

    > span {
      vertical-align: middle;
    }
  }
  .invoice-amount-summary {
    margin-top: $spacer-md;

    @include media-breakpoint-up('md') {
      margin-top: $spacer-lg;
    }

    .invoice-status {
      vertical-align: middle;
    }
  }
}


.case-details.ui-card {
  @include media-breakpoint-down('xs') {
    @include border-left-radius(0);
    @include border-right-radius(0);
  }

  @include media-breakpoint-up('md') {
    margin-bottom: $spacer-xl;
  }

  .invoice-actions {
    margin-bottom: $spacer-md;

    @include media-breakpoint-up('md') {
      float: right;
    }
  }
  .recipient {
    margin-bottom: $spacer-sm;

    h4 {
      margin-bottom: .25rem;
    }
    p {
      line-height: 1.375;
      margin-bottom: .25rem;
    }
    .invoice-reference {
      .text-muted {
        color: color("black", light);
      }
    }
  }
}

.case-details__web-invoice-link {
  margin-top: 2rem;
  font-weight: 500;
}

.detail-group {
  margin-bottom: -.75rem !important;

  &--adjust {
    justify-content: space-between;
  }

  @include media-breakpoint-up('sm') {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.detail-block {
  margin-bottom: .75rem;
  margin-right: $spacer-lg;

  .detail-label {
    margin-bottom: .25rem;
    color: color("black", light);
  }
  .detail-value {
    margin-bottom: 0;
    font-size: $font-size-lg;
    font-weight: 500;
    line-height: 1;

    small {
      margin-left: .5rem;
    }
  }
}

.dc-case-debt {
  border-top: $border-width solid color("gray", lightest);
  padding-top: 1rem;

  .debt-total {
    .debt-label {
      margin-bottom: .25rem;
      font-weight: 500;
    }
    .debt-remainder {
      @extend .ui-alert--attention;
      padding: .675rem .75rem;
      border-radius: $border-radius-sm;
      text-align: center;
      font-weight: 700;
    }
  }
  .debt-breakdown {
    .detail-block {
      display: inline-block;
      margin-right: $spacer-lg;
    }
  }
}


.amount-total {
  margin-right: $spacer-md;

  .detail-label,
  .lead {
    color: color("gray");
    margin-bottom: 0;

    @include media-breakpoint-down('xs') {
      display: block;
    }
  }
  .amount-label {
    color: color("black");
  }
  .amount-value {
    @include media-breakpoint-up('sm') {
      margin: 0;
    }
  }
}
.amount-payed {
  .amount-total + & {
    padding-bottom: .25rem;
  }
}
.amount-debt {
  .amount-label {
    margin-bottom: .25rem;
    font-weight: 500;
  }
  .amount-value {
    @extend .ui-alert--attention;
    margin: 0;
    padding: .675rem .75rem;
    border-radius: $border-radius-sm;
    text-align: center;
    font-weight: 700;
  }
}
.payment-list {
  margin-right: .875rem;

  .lead {
    margin-bottom: 0;
    color: color("black");
  }
  .ui-table__list-item-col {
    flex-basis: auto;
  }
}

.invoice-amount-summary {
  .row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0
  }
}
