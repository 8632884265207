

$ui-button-transition: $transition, box-shadow 0s;

/* --------------------------------------------------------------------------
   Button Mixins
   -------------------------------------------------------------------------- */

@mixin ui-button--xs {
    padding: $button-padding-xs-y $button-padding-xs-x;
    border-radius: $button-border-radius-xs;
    border-width: $border-width;
    font-size: $button-font-size-xs;
}

@mixin ui-button--sm {
    padding: $button-padding-sm-y $button-padding-sm-x;
    border-radius: $button-border-radius-sm;
    border-width: $border-width;
    font-size: $button-font-size-sm;
}

// Resets to default size
@mixin ui-button--md {
    padding: $button-padding-md-y $button-padding-md-x;
    border-radius: $button-border-radius-lg;
    border-width: $button-border-width;
    font-size: $button-font-size-md;
}

@mixin ui-button--lg {
    padding: $button-padding-lg-y $button-padding-lg-x;
    border-radius: $button-border-radius-lg;
    border-width: $button-border-width;
    font-size: $button-font-size-lg;
}

@mixin ui-button--xl {
    padding: $button-padding-xl-y $button-padding-xl-x;
    border-radius: $button-border-radius-xl;
    border-width: $button-border-width;
    font-size: $button-font-size-xl;
}